import React, {useState} from 'react';
import {Typography, Box, Container} from '@mui/material';
import {styled} from '@mui/material/styles';
import {QueryTip as QueryTipType} from "../../../types/home.types";
import {GradientWrapper} from "../../../components/shared/TextGradientWarper";
import {LinearGradient} from "react-text-gradients";


const RegularTitle = styled('span')(({theme}) => ({
    color: theme.palette.text.primary,
    fontSize: '2.5rem',
    fontWeight: 700,
}));

const HighlightedTitle = styled('span')(({theme}) => ({
    fontSize: '2.5rem',
    fontWeight: 700,
    display: 'inline-block',
}));

const SlideContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(4),
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const NavigationDots = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
}));

const NavigationDot = styled('button')<{ active: boolean }>(({theme, active}) => ({
    width: active ? '24px' : '12px',
    height: '12px',
    borderRadius: '999px',
    border: 'none',
    backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[400],
    },
}));


const QueryTip: React.FC<QueryTipType> = ({title, carousel_content}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        carousel_content.first,
        carousel_content.second,
        carousel_content.third
    ];

    const handleDotClick = (index: number) => {
        setCurrentSlide(index);
    };


    return (
        <Container maxWidth={"lg"} >
            <Box sx={{textAlign: 'center', mb: 6}}>
                <Typography variant="h2" gutterBottom>
                    <RegularTitle>
                        {title.regular}
                    </RegularTitle>

                    <GradientWrapper>
                        <LinearGradient gradient={['to right', '#4066FF ,#FF68F0']}>
                            <HighlightedTitle>
                                {title.highlighted}
                            </HighlightedTitle>
                        </LinearGradient>
                    </GradientWrapper>
                </Typography>
            </Box>

            <SlideContent>
                <Typography
                    variant="h4"
                    component="p"
                    color="text.secondary"
                    sx={{maxWidth: '800px', textAlign: 'center'}}
                >
                    {slides[currentSlide]}
                </Typography>
            </SlideContent>

            <NavigationDots>
                {slides.map((_, index) => (
                    <NavigationDot
                        key={index}
                        active={currentSlide === index}
                        onClick={() => handleDotClick(index)}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </NavigationDots>
        </Container>
    );
};

export default QueryTip;