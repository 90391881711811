import { FooterConfig } from '../types/footer.types';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

export const FOOTER_CONFIG: FooterConfig = {
    company: {
        description: 'Medint is a healthcare technology company that leverages advanced Al and human expertise to provide precise, up-to-date medical insights for doctors and medical professionals. Our mission is to empower doctors with the best tools and insights to enhance patient care and improve treatment outcomes.',
        logo: {
            src: '/medint-logo-full-transparent.png',
            alt: 'Logo',
            height: 60,
        },
    },
    quickLinks: {
        title: 'Quick Links',
        links: [
            { label: 'Home', path: '/home' },
            { label: 'Log In', path: '/login' },
            { label: 'Registration', path: '/registration'}
        ],
    },
    legalLinks: {
        title: 'Legal Links',
        links: [
            { label: 'Privacy Policy', path: '/privacy-policy' },
            { label: 'Terms and Conditions', path: '/terms-and-conditions' },
        ],
    },
    contact: {
        email: 'info@medint.ai',
        address: '144 Derech Begin, Tel-Aviv',
    },
    socialMedia: {
        title: 'Follow Us',
        icons: [
            {
                id: 'facebook',
                icon: FacebookIcon,
                href: 'https://facebook.com',
                label: 'Follow us on Facebook'
            },
            {
                id: 'twitter',
                icon: TwitterIcon,
                href: 'https://twitter.com',
                label: 'Follow us on Twitter'
            },
            {
                id: 'linkedin',
                icon: LinkedInIcon,
                href: 'https://linkedin.com',
                label: 'Connect with us on LinkedIn'
            },
            {
                id: 'youtube',
                icon: YouTubeIcon,
                href: 'https://youtube.com',
                label: 'Subscribe to our YouTube channel'
            },
            {
                id: 'instagram',
                icon: InstagramIcon,
                href: 'https://instagram.com',
                label: 'Follow us on Instagram'
            }
        ]
    }
};