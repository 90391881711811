import React, {useState} from 'react';
import {
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import {ResearchQuery} from '../../../types/dashboard.types';
import FileUploadComponent from "../components/FileUploader";

interface QueryFormProps {
    onSubmit: (query: ResearchQuery) => void;
}

const QueryForm: React.FC<QueryFormProps> = ({onSubmit}) => {
    const [queryData, setQueryData] = useState<ResearchQuery>({
        query: '',
        patientSummary: '',
        files: []
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setQueryData(prev => ({
                ...prev,
                files: Array.from(e.target.files!)
            }));
        }
    };

    const removeFile = (indexToRemove: number) => {
        setQueryData(prev => ({
            ...prev,
            files: prev.files?.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(queryData,);
    };

    return (
        <Paper sx={{p: 3, mt: 3, borderRadius: 2}}>
            <Typography variant="h6" gutterBottom>
                Submit Your Query
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Provide your clinical dilemma or specify a topic for research.
            </Typography>

            <form onSubmit={handleSubmit}>
                <TextField
                    multiline
                    rows={4}
                    fullWidth
                    label="Query"
                    value={queryData.query}
                    onChange={(e) => setQueryData(prev => ({
                        ...prev,
                        query: e.target.value
                    }))}
                    margin="normal"
                />

                <TextField
                    multiline
                    rows={3}
                    fullWidth
                    label="Patient Summary (Optional)"
                    value={queryData.patientSummary}
                    onChange={(e) => setQueryData(prev => ({
                        ...prev,
                        patientSummary: e.target.value
                    }))}
                    margin="normal"
                />

                <FileUploadComponent
                    queryData={queryData}
                    handleFileChange={handleFileChange}
                    removeFile={removeFile}
                    handleSubmit={handleSubmit}
                />
            </form>
        </Paper>
    );
};

export default QueryForm;