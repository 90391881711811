import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack, Typography, CircularProgress } from '@mui/material';
import * as pdfjsLib from 'pdfjs-dist';
import {
    ZoomIn,
    ZoomOut,
    NavigateBefore,
    NavigateNext,
} from '@mui/icons-material';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`;

interface PdfViewerProps {
    url: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ url }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const textLayerRef = useRef<HTMLDivElement>(null);
    const renderTaskRef = useRef<any>(null);
    const [pdf, setPdf] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [scale, setScale] = useState(1);
    const [loading, setLoading] = useState(true);

    // Cleanup function for rendering tasks
    const cleanupRenderTask = () => {
        if (renderTaskRef.current) {
            renderTaskRef.current.cancel();
            renderTaskRef.current = null;
        }
    };


    // Load PDF document
    useEffect(() => {
        let pdfDoc: pdfjsLib.PDFDocumentProxy | null = null;

        const loadPdf = async () => {
            try {
                setLoading(true);
                const loadingTask = pdfjsLib.getDocument(url);
                const pdfDoc = await loadingTask.promise;
                setPdf(pdfDoc);
                setTotalPages(pdfDoc.numPages);
                setLoading(false);
            } catch (error) {
                console.error('Error loading PDF:', error);
                setLoading(false);
            }
        };

        loadPdf();

        // Cleanup
        return () => {
            cleanupRenderTask();
            if (pdfDoc) {
                pdfDoc.destroy();
            }
        };
    }, [url]);

    // Render page
    useEffect(() => {
        const renderPage = async () => {
            if (!pdf || !containerRef.current || !canvasRef.current || !textLayerRef.current) return;

            try {
                setLoading(true);
                // Cancel any ongoing render task
                cleanupRenderTask();

                const page = await pdf.getPage(currentPage);
                const container = containerRef.current;

                // Calculate initial scale to fit container width
                const viewport = page.getViewport({ scale: 1.5 });
                const containerWidth = container.clientWidth - 32; // Account for padding
                const initialScale = containerWidth / viewport.width;
                const scaledViewport = page.getViewport({ scale: initialScale * scale });

                // Set canvas dimensions
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                if (!context) return;

                canvas.height = scaledViewport.height;
                canvas.width = scaledViewport.width;

                // Clear previous content
                context.clearRect(0, 0, canvas.width, canvas.height);

                // Clear text layer
                textLayerRef.current.innerHTML = '';

                // Render PDF page
                const renderContext = {
                    canvasContext: context,
                    viewport: scaledViewport,
                };

                // Render page and text layer
                const renderTask = page.render(renderContext);
                renderTaskRef.current = renderTask;

                // Get text content and render it
                const textContent = await page.getTextContent();
                await renderTask.promise;


                const textLayer = new pdfjsLib.TextLayer({
                    textContentSource: textContent,
                    container: textLayerRef.current,
                    viewport: scaledViewport
                });
                // await textLayer.render();

                await renderTaskRef.current.promise;
                setLoading(false);
                page.cleanup();
            } catch (error) {
                const err = error as Error;
                if (err.name !== 'RenderingCancelled') {
                    console.error('Error rendering page:', err);
                }
                setLoading(false);
            }
        };

        renderPage();

        // Cleanup when component unmounts or when dependencies change
        return () => {
            cleanupRenderTask();
        };
    }, [pdf, currentPage, scale]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleZoomIn = () => {
        setScale(prevScale => Math.min(prevScale + 0.25, 3));
    };

    const handleZoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.25, 0.5));
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Toolbar */}
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                    p: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper'
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={handlePreviousPage} disabled={currentPage <= 1}>
                        <NavigateBefore />
                    </IconButton>
                    <Typography>
                        {currentPage} / {totalPages}
                    </Typography>
                    <IconButton onClick={handleNextPage} disabled={currentPage >= totalPages}>
                        <NavigateNext />
                    </IconButton>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={handleZoomOut} disabled={scale <= 0.5}>
                        <ZoomOut />
                    </IconButton>
                    <Typography>{Math.round(scale * 100)}%</Typography>
                    <IconButton onClick={handleZoomIn} disabled={scale >= 3}>
                        <ZoomIn />
                    </IconButton>
                </Stack>
            </Stack>

            {/* PDF Content */}
            <Box
                ref={containerRef}
                sx={{
                    flex: 1,
                    overflow: 'auto',
                    position: 'relative',
                    bgcolor: '#f5f5f5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    p: 2
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    <canvas ref={canvasRef} style={{ display: 'block' }} />
                    <div
                        ref={textLayerRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            pointerEvents: 'none',
                            userSelect: 'text',
                            transform: `scale(${scale})`,
                            transformOrigin: '0 0',
                            width: '100%',
                            height: '100%'
                        }}
                        className="textLayer"
                    />
                </Box>
                {loading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PdfViewer;