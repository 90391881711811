import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import {SupportFormData} from "../../../../../types/support.types";


const SupportForm: React.FC = () => {
    const [formData, setFormData] = useState<SupportFormData>({
        topic: '',
        query: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form Submitted:', formData);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
            <TextField
                label="Topic"
                name="topic"
                value={formData.topic}
                onChange={handleChange}
                fullWidth
                required
                sx={{ mb: 2 }}
            />
            <TextField
                label="Query"
                name="query"
                value={formData.query}
                onChange={handleChange}
                fullWidth
                required
                multiline
                rows={4}
                sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </Box>
    );
};

export default SupportForm;