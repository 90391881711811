import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterUser, ConfirmRegistration } from '../types/auth.types';
import { authApi } from '../services/auth/auth.services';
import {saltAndHashPassword} from "../utils/saltyPassword";

export const useRegister = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
    const [registeredEmail, setRegisteredEmail] = useState<string>('');


    const register = async (userData: RegisterUser) => {
        setIsLoading(true);
        setError(null);

        try {
            const {email, password, title, firstName, lastName, isCertifiedMedical, professionalAffiliation, agreeToPrivacyPolicy, phoneNumber} = userData;

            // Hash the password using the utility function
            // const saltedPassword = saltAndHashPassword(password);

            const response = await authApi.register({email, password, title, firstName, lastName, isCertifiedMedical, professionalAffiliation, agreeToPrivacyPolicy, phoneNumber});
            setRegisteredEmail(userData.email);
            setIsConfirmationRequired(true);
            return response;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Registration failed');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const confirmRegistration = async (data: ConfirmRegistration) => {
        setIsLoading(true);
        setError(null);

        try {
            await authApi.confirmRegistration(data);
            // Registration successful, redirect to login
            navigate('/login', { state: { registrationSuccess: true } });
            return true;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Confirmation failed');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const resendConfirmationCode = async (email: string) => {
        setIsLoading(true);
        setError(null);

        try {
            await authApi.resendConfirmationCode(email);
            return true;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to resend confirmation code');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        register,
        confirmRegistration,
        resendConfirmationCode,
        isLoading,
        error,
        isConfirmationRequired,
        registeredEmail,
        clearError: () => setError(null)
    };
};