import React, {useState, useEffect} from 'react';
import {Typography, Box, Container} from '@mui/material';
import {styled} from '@mui/material/styles';
import {PartnersSection as PartnersSectionType} from "../../../types/home.types";
import {GradientWrapper} from "../../../components/shared/TextGradientWarper";
import {LinearGradient} from "react-text-gradients";


const RegularTitle = styled('span')(({theme}) => ({
    color: theme.palette.text.primary,
    fontSize: '2.5rem',
    fontWeight: 700,
}));

const HighlightedTitle = styled('span')(({theme}) => ({
    fontSize: '2.5rem',
    fontWeight: 700,
    display: 'inline-block',
}));

const Description = styled(Typography)(({theme}) => ({
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
}));

const LogoGrid = styled(Box)(({theme}) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(8),
    marginBottom: theme.spacing(4),
}));

const LogoContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    transition: 'all 0.3s ease',
    filter: 'grayscale(100%)',
    opacity: 1,
    '&:hover': {
        filter: 'grayscale(0%)',
        opacity: 1,
    },
}));

const NavigationDots = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
}));

const NavigationDot = styled('button')<{ active: boolean }>(({theme, active}) => ({
    width: active ? '24px' : '12px',
    height: '12px',
    borderRadius: '999px',
    border: 'none',
    backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[400],
    },
}));

const LOGO_SIZE = 120;


const Partners: React.FC<PartnersSectionType> = ({title, carousel_content}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const itemsPerPage = 3;

    const totalPages = Math.ceil(carousel_content.icons.length / itemsPerPage);

    const currentIcons = carousel_content.icons.slice(
        currentSlide * itemsPerPage,
        (currentSlide + 1) * itemsPerPage
    );

    const handleDotClick = (index: number) => {
        setCurrentSlide(index);
    };

    useEffect(() => {
        if (isHovered) return;

        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === totalPages - 1 ? 0 : prevSlide + 1
            );
        }, 10000); // Changes slide every 3 seconds

        return () => clearInterval(timer);
    }, [totalPages]);


    return (
        <Container maxWidth={"lg"}>
            <Box sx={{textAlign: 'center', mb: 6}}>
                <Typography variant="h2" gutterBottom>
                    <RegularTitle>
                        {title.regular}{' '}
                    </RegularTitle>
                    <GradientWrapper>
                        <LinearGradient gradient={['to right', '#4066FF ,#FF68F0']}>
                            <HighlightedTitle>
                                {title.highlighted}
                            </HighlightedTitle>
                        </LinearGradient>
                    </GradientWrapper>
                </Typography>

                {carousel_content.description && (
                    <Description variant="h6">
                        {carousel_content.description}
                    </Description>
                )}
            </Box>

            <LogoGrid>
                {currentIcons.map((icon, index) => (
                    <LogoContainer key={index}
                                   onMouseEnter={() => setIsHovered(true)}
                                   onMouseLeave={() => setIsHovered(false)}
                    ы>
                        <img
                            src={icon.path}
                            alt={icon.alt}
                            width={icon.width}
                            height={icon.height}
                            style={{maxWidth: '100%', height: 'auto', objectFit: 'contain'}}
                        />
                    </LogoContainer>
                ))}
            </LogoGrid>

            {totalPages > 1 && (
                <NavigationDots>
                    {[...Array(totalPages)].map((_, index) => (
                        <NavigationDot
                            key={index}
                            active={currentSlide === index}
                            onClick={() => handleDotClick(index)}
                            aria-label={`Go to page ${index + 1}`}
                        />
                    ))}
                </NavigationDots>
            )}
        </Container>
    );
};

export default Partners;