import { styled } from '@mui/material/styles';
import {Box, Typography, Button, Grid2, Container} from '@mui/material';
import {EXAMPLES_STYLE, HERO_CONTENT} from "../../constants/home.constants";


export const HeroContainer = styled(Box)(({ theme }) => ({
    minHeight: '70vh',
    position: 'relative',
    overflow: 'hidden',
    background: HERO_CONTENT.style.backgroundGradient,
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '50%',
        backgroundImage: `url("${HERO_CONTENT.style.backgroundImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '30%',
        backgroundSize: '65%',
        opacity: 0.1,
        zIndex: 0
    }
}));

export const HeroContent = styled(Box)(({ theme }) => ({
    maxWidth: '37.5rem',
    width: '50%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(10),
    padding: theme.spacing(10),
}));

export const DisclaimerContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: "linear-gradient(180deg, #f5f7ff 10%,  #ffffff 100%)"
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({

}));


export const GridBackground = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
    zIndex: 0,
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url("/home_page_images/grid-pattern.svg")`,
        backgroundSize: '100%',
        opacity: 0.1,
    }
}));

export const MainIllustration = styled(Box)(({ theme }) => ({
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    flex: 1,
    opacity: 10,
    display: 'flex',
    alignItems: 'center',
    '& img': {
        maxWidth: '100%',
        height: 'auto',
        opacity: 1,
    }
}));

export const HighlightedText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 700,
}));

export const CTAButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(1.5, 4),
    borderRadius: '25px',
}));

export const FeaturesGrid = styled(Grid2)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 2fr))',
    gap: theme.spacing(20),
    padding: theme.spacing(10, 15),
}));


export const ExamplesContainer = styled(Box)(({ theme }) => ({
    minHeight: '80vh',
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: theme.spacing(15),
    paddingTop: theme.spacing(10),
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '60%',
        backgroundImage: `url("${EXAMPLES_STYLE.style.backgroundImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '5rem -9rem',
        backgroundSize: '80%',
        opacity: 0.05,
        zIndex: 0
    }
}));




export const BusinessCardContainer = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(30),
    paddingTop: theme.spacing(30),
}));

export const ApproachSection = styled(Box)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    background: HERO_CONTENT.style.backgroundGradient,
    padding: theme.spacing(12, 0),
    overflow: 'hidden',
}));

export const ApproachContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    zIndex: 2,
    display: 'grid',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
}));

export const ApproachGridBackground = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url("/home_page_images/grid-pattern.svg")`,
        backgroundSize: '70%',
        backgroundRepeat: 'no-repeat',
        opacity: 0.1,
        boxShadow: 'inset 50px 50px 50px 50px white',
    }
}));

export const DefaultStyledBox = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(10),
}))
export const DefaultStyledContainer = styled(Container)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const TestimonialSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(8, 0),
    overflow: 'hidden',
    backgroundColor: theme.palette.common.black,
}));

export const FeedbackGridBackground = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url("/home_page_images/white-grid-pattern.svg")`,
        backgroundSize: '20%',
        backgroundRepeat: 'repeat',
        opacity: 0.25,
        filter: 'brightness(1.2)',
    },
}));

export const FormStyledContainer = styled(Box)(({ theme }) => ({
    minHeight: '80vh',
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: theme.spacing(30),
    paddingTop: theme.spacing(5),
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '60%',
        backgroundImage: `url("${EXAMPLES_STYLE.style.backgroundImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '5rem 5rem',
        backgroundSize: '80%',
        opacity: 0.05,
        zIndex: -1
    }
}));