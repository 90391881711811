import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import {LoginFooterProps} from "../../../types/login.types";

const FooterContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    textAlign: 'center'
}));

const LoginFooter: React.FC<LoginFooterProps> = ({ description, href, link_text }) => {
    return (
        <FooterContainer>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                {description}
            </Typography>
            <Link href={href} variant="body2" color="primary">
                {link_text}
            </Link>
        </FooterContainer>
    );
};

export default LoginFooter;
