import React from 'react';
import {
    Box,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Link,
    InputAdornment,
    IconButton, Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoginFormProps, LoginFormData } from '../../../types/login.types';

const FormContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '600px'
}));

const LoginForm: React.FC<LoginFormProps> = ({config, onSubmit, isLoading, error}) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [formData, setFormData] = React.useState<LoginFormData>({
        email: '',
        password: '',
        rememberMe: false
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'rememberMe' ? checked : value
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(formData);
    };

    return (
        <FormContainer
            component="form"
            onSubmit={handleSubmit}>
            <TextField
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '25px'
                    }
                }}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
                placeholder={config.placeholders.email}
            />
            <TextField
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '25px'
                    }
                }}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                placeholder={config.placeholders.password}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="rememberMe"
                            checked={formData.rememberMe}
                            onChange={handleChange}
                            color="primary"
                        />
                    }
                    label={config.labels.rememberMe}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, borderRadius: '25px', maxWidth: '30%'}}
                    disabled={isLoading}
                    endIcon={config.button.icon && <config.button.icon />}
                >
                    {config.button.text}
                </Button>
                <Link href="#" variant="body2">
                    {config.labels.forgotPassword}
                </Link>
            </Box>
        </FormContainer>
    );
};

export default LoginForm;