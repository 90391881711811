import {PolicyPageProps} from "../types/policy.types";

export const TERMS_OF_USE: PolicyPageProps = {
    pageTitle: "Terms of Use",
    lastUpdated: "December 12, 2024",
    backgroundGradient: "linear-gradient(110deg, #195AF1 10%, #FF7376 100%)",
    sections: [
        {
            title: "Agreement to Terms",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "By accessing our service, you agree to be bound by these Terms of Use.",
                        "These terms of use (“Terms”) govern your use of the website that links to these Terms (“Website”), operated by Medint Medical Intelligence Ltd. (“we”, “our”, “us”). If you have contracted Medint for the provision of Medint’s services, these services are governed by the Medint Services Agreement, in the form executed between you and us. Please carefully read these Terms, as they constitute a binding agreement between you and us. By accepting these Terms or by accessing or using the Website, you signify your agreement with these Terms. If you do not agree to these Terms, you may not access or use the Website.",

                    ]
                }
            ]
        },
        {
            title: "Grant of Right",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We hereby grant you a non-exclusive, non-transferable, limited, personal, revocable right to access the Website, subject to the Terms herein.",
                    ],
                }
            ]
        },
        {
            title: "Acceptable Use of the Website",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "You may not publish details and content from the Website in other websites or other media platforms in any form.",
                        "Do not copy content of the Website, embed it in other websites, display it using framing techniques, create mirroring websites, sell, grant a license to use, rent, lend or distribute the Website’s content in any way.",
                        "Do not link content of the Website separately from the webpage where the content is originally located. When linking to webpages of the Website, no information should be hidden, blocked, modified or deleted from the webpages of the Website.",
                        "The Website and the content within may not be accessed in order to develop or create a similar or competing Website, including by collecting information from the Website.",
                        "The Website may not be used for purposes of examination, scanning, scraping, hacking, including hacking the Website security systems or any other computer and communication systems serving it, interfering with the Website’s operations, manipulating the Website’s activities, systematic access to the Website’s systems using automated software tools, such as ‘bots’ and ‘crawlers’, and access to the features or functionalities of the Website that are not intended to be accessible or that are not documented.",
                        "You must not misuse the Website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to the Website, the server on which it is stored, or any server, computer or database connected to it.",
                        "The Website may not be used for any activity that constitutes or encourages the commission of criminal offense or civil wrong or violates applicable laws, including laws in connection with deception, fraud, forgery, privacy protection, identity theft, viruses and other malicious software distribution, right to publicity, slander, harassment and obscene publications."
                    ],
                }
            ]
        },
        {
            title: "Privacy and Data protection",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We use and process personal information related to you according to our privacy policy https://medint.ai/privacy-policy.",
                    ],
                }
            ]
        },
        {
            title: "Publication of Content",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We will publish articles and other content that are related to our areas of activity, either on the Website or by using our newsletter. Such content is either written by us or by other writers, and is provided for the purpose of your benefits only. The content we publish is not professional opinions and should not be treated as such.",
                    ],
                }
            ]
        },
        {
            title: "No Medical Advice",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "The Website may contain information about medical conditions and treatments. Such information is intended for informational purposes only, is not meant to consist of medical advice, and should not be treated as such.",
                        "We are committed to diligent research. However, given the nature of the field of medicine, We cannot, and do not, warrant that any of the content on the Website is complete, reliable, accurate, useful, or that such content will meet your needs. Any information on the Website is provided without any representation or warranty, express or implied. We do not warrant that the information on the Website is complete, accurate, or up-to-date.",
                        "We do not advise to rely on the information on the Website as an alternative to medical advice from M.D`s or other healthcare professionals. If you have any specific questions about any medical matter, you should consult your M.D or other healthcare professional."
                    ],
                }
            ]
        },
        {
            title: "Links",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "The Website may contain links to other websites, information or content provided by third parties. We do not operate or monitor these third-party websites and content. You may find them, or the information and content posted therein, not compatible with your requirements, objectionable, annoying, improper, unlawful or immoral. These websites are subject to their own terms of use and privacy policies.",
                        "By linking to a certain website, we do not endorse or sponsor its content, nor do we confirm the accuracy, credibility, authenticity, reliability, validity, integrity, or legality of such content.",
                        "We assume no responsibility for such third-party websites or content, or their availability or for any transactions made between you and such third-party websites. We will not be liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with your use of or reliance on any such content, goods or services available on or through any third-party websites or content."
                    ],
                }
            ]
        },
        {
            title: "Intellectual Property",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "The Website includes user interfaces, graphic designs, illustrations, texts, icons, software code and more. All the above are protected by copyright and through additional intellectual property rights. All rights to the Website and any content thereof (except for content provided by you via the Website’s forms) are owned by us or we have received permission from the owners to use such content on the Website.",
                        "You may not: (i) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Website; or (ii) remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Website.",
                        "Our trademarks (whether registered or not), name, logo and domain name – are our sole property. You may not use them, or any confusingly similar mark or text, without our prior express written consent."
                    ],
                }
            ]
        },
        {
            title: "Information Security",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We and our hosting service providers implement systems, applications and procedures to secure your use of the Website and to minimize the risks of theft, damage, loss of information, or unauthorized access or use of information. However, due to the nature of the internet and although we make such efforts, we cannot guarantee that our systems will be immune from any wrongdoings, malfunctions, unlawful interceptions or access, or other kinds of abuse and misuse.",
                    ],
                }
            ]
        },
        {
            title: "Warranty and Limitation of Liability",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "Your use of the Website and your exposure to information on the Website is at your own risk. We do not take any responsibility for the activity on the Website, and we do not provide any guarantees with respect to the content contained therein. You will be responsible for all acts and omissions associated with your use of the Website, including use thereof by any other third party on your behalf or at your permission.",
                        "We do not provide any warranty whatsoever, on behalf of any third party, and it is hereby made explicitly clear that we will not be held responsible for any acts or omissions on the part of any third party",
                        "Under no circumstances and under no legal theory, tort, contract, or otherwise, will we be liable to you or any other person for any indirect, special, incidental, punitive, exemplary or consequential damages of any type including, without limitation, loss of data, loss of goodwill, business interruption, computer failure or malfunction, loss of business information, and all other commercial damages or losses even if we have been informed of the possibility of such damages, or for any claim by any other party",
                        "We will make efforts to run the Website efficiently and accessible at all times. However, the Website’s operation is dependent upon various factors such as software, hardware and communication networks. By their nature, these factors are not fault free. We do not warrant that the Website will operate in an uninterrupted or error-free manner, or that it will always be available, free from all harmful components, or that at all times it will completely safe, and secured from unauthorized access to our computers, immune from damages, free of malfunctions, bugs or failures, including, but not limited to, hardware failures, software failures and software communication failures, originating either in us or any other service provider. Such incidents will not be considered a breach of these Terms.",
                        "We may from time to time change the Website’s structure, layout, design or display, as well as the scope and availability of the information and content therein, without giving any prior notice. Changes of this character by their very nature are likely to result in glitches or cause inconvenience. We will not be held responsible for any outcome of these changes, and failures resulting from them."
                    ],
                }
            ]
        },
        {
            title: "General",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We may change these Terms to meet technical, operational and legal changes. We will post a notice about changes made to these Terms on the Website, and by continuing your use of the Website after the new changes have taken effect, you indicate your agreement to the amended Terms.",
                        "No waiver, concession, extension, representation, alteration, addition or derogation from these Terms will be effective unless affected in writing and expressly.",
                        "If any provision of these Terms is held by a court of competent jurisdiction to be illegal, invalid, unenforceable, or otherwise contrary to law, the remaining provisions of these Terms will remain in full force and effect.",
                    ],
                }
            ]
        },
        {
            title: "Contact Us",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "If you have any questions or concerns regarding these Terms, please email us at: support@medint.ai",
                        "We will review your query and make a good-faith effort to respond promptly.",
                    ],
                }
            ]
        },
    ]
};