import React from 'react';
import { DashboardLayout } from "@toolpad/core";
import { Outlet } from 'react-router-dom';

const DashboardWrapper: React.FC = () => {
    return (
        <DashboardLayout>
            <Outlet />
        </DashboardLayout>
    );
};

export default DashboardWrapper;