import React from 'react';
import Header from '../components/header/Header';
import Footer from "../components/footer/Footer";
import {MainLayoutProps} from "../types/layouts.types";


const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default MainLayout;