import React from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import RegistrationHeader from './components/RegistrationHeader';
import RegistrationForm from './forms/RegistrationForm';
import RegistrationFooter from './components/RegistrationFooter';
import { RegistrationFormData } from '../../types/registration.types';
import { REGISTRATION_PAGE_CONFIG } from "../../constants/registration.constants";
import {useRegister} from "../../hooks/useRegister";
import {RegisterUser} from "../../types/auth.types";
import ConfirmationForm from "./forms/ConfirmationForm";

const RegistrationContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(15)
}));

const RegistrationPaper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
}));

const Registration: React.FC = () => {
    const {
        register,
        confirmRegistration,
        resendConfirmationCode,
        isLoading,
        error,
        isConfirmationRequired,
        registeredEmail
    } = useRegister();

    const handleSubmit = async (formData: RegistrationFormData) => {
        const registrationData: RegisterUser = {
            email: formData.email,
            password: formData.password,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            professionalAffiliation: formData.professionalAffiliation.toString(),
            title: formData.title.toString(),
            isCertifiedMedical: formData.isCertifiedMedical.toString(),
            agreeToPrivacyPolicy: formData.agreeToPrivacyPolicy.toString()
        };

        await register(registrationData);
    };

    const handleConfirmation = async (code: string) => {
        await confirmRegistration({
            email: registeredEmail,
            code
        });
    };

    const handleResendCode = async () => {
        await resendConfirmationCode(registeredEmail);
    };

    return (
        <RegistrationContainer maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <RegistrationPaper>
                    <RegistrationHeader {...REGISTRATION_PAGE_CONFIG.header} />
                    {!isConfirmationRequired ? (
                        <RegistrationForm
                            config={REGISTRATION_PAGE_CONFIG}
                            onSubmit={handleSubmit}
                            isLoading={isLoading}
                            error={error}
                        />
                    ) : (
                        <ConfirmationForm
                            onSubmit={handleConfirmation}
                            onResendCode={handleResendCode}
                            isLoading={isLoading}
                            error={error}
                            email={registeredEmail}
                        />
                    )}
                    <RegistrationFooter {...REGISTRATION_PAGE_CONFIG.footer} />
                </RegistrationPaper>
            </Box>
        </RegistrationContainer>
    );
};

export default Registration;