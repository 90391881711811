import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {
    HERO_CONTENT,
    FEATURE_CARDS,
    APPROACH_CARD,
    HOW_DOES_IT_WORK_CARD,
    QUERY_TIP,
    EXAMPLES_CARD,
    PARTNERS_CONTENT,
    TRIAL_FORM,
    BUSINESS_CARD,
    DISCLAIMER_CONTENT,
    FEEDBACK_CARDS
} from '../../constants/home.constants';

import {
    HeroContainer,
    HeroContent,
    HighlightedText,
    CTAButton,
    FeaturesGrid,
    MainIllustration,
    ExamplesContainer,
    BusinessCardContainer,
    GridBackground,
    ButtonContainer,
    DisclaimerContent,
    ApproachContainer,
    ApproachGridBackground,
    ApproachSection,
    DefaultStyledBox,
    TestimonialSection,
    FeedbackGridBackground,
    FormStyledContainer, DefaultStyledContainer
} from './HomeStyles';

import FeatureCard from './components/FeatureCard';
import MedicalInsights from "./components/MedicalInsights";
import ApproachCard from "./components/ApproachCard";
import HowDoesItWork from "./components/HowDoesItWork";
import QueryTip from "./components/QueryTip";
import ExamplesCard from "./components/Examples";
import PricingContainer from "./components/PricingCard";
import Partners from "./components/PartnerCarousel";
import TrialForm from "./forms/StartTrialForm";
import BusinessCard from "./components/BusinessCard";
import Disclaimer from "./components/Disclaimer";
import FeedbackCard from "./components/FeedbackCard";
import {Title} from "@mui/icons-material";
import {LinearGradient} from "react-text-gradients";
import {GradientWrapper} from "../../components/shared/TextGradientWarper";
import {styled} from "@mui/material/styles";
import Registration from "../registration/Registration";


const TitleWrapper = styled(Box)(({theme}) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const RegularTitle = styled('span')(({theme}) => ({
    color: theme.palette.text.primary,
    fontSize: '2.5rem',
    fontWeight: 700,
}));

const HighlightedTitle = styled('span')(({theme}) => ({
    fontSize: '2.5rem',
    fontWeight: 700,
}));

const Home: React.FC = () => {
    return (
        <Box>
            <HeroContainer>
                <HeroContent>
                    <Typography variant="h2" gutterBottom>
                        {HERO_CONTENT.title.firstLine}{' '}
                        <HighlightedText variant="h2" style={{display: 'inline'}}>
                            {HERO_CONTENT.title.highlighted}
                        </HighlightedText>{' '}
                        {HERO_CONTENT.title.lastLine}
                    </Typography>

                    <Typography variant="h5" color="#000000" gutterBottom>
                        {HERO_CONTENT.subtitle}
                    </Typography>

                    <ButtonContainer>
                        <CTAButton
                            variant="contained"
                            color="primary"
                            href={HERO_CONTENT.ctaButton.href}
                            size="large"
                        >
                            {HERO_CONTENT.ctaButton.text}
                        </CTAButton>
                    </ButtonContainer>
                </HeroContent>

                <GridBackground>
                    <MainIllustration>
                        <img
                            src="/home_page_images/BANNER_BALL.svg"
                            alt="Medical AI Illustration"
                        />
                    </MainIllustration>
                </GridBackground>
            </HeroContainer>

            {/*<DisclaimerContent>*/}
            {/*    <Disclaimer {...DISCLAIMER_CONTENT}/>*/}
            {/*</DisclaimerContent>*/}

            {/*<DefaultStyledContainer>*/}
            {/*    <FeaturesGrid>*/}
            {/*        {FEATURE_CARDS.map((feature, index) => (*/}
            {/*            <FeatureCard*/}
            {/*                key={index}*/}
            {/*                icon={feature.icon}*/}
            {/*                alt={feature.alt}*/}
            {/*                title={feature.title}*/}
            {/*                description={feature.description}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </FeaturesGrid>*/}
            {/*</DefaultStyledContainer>*/}


            {/*<DefaultStyledContainer maxWidth="lg">*/}
            {/*    <Divider orientation="horizontal" sx={{maxWidth: 1200, display: 'flex'}}/>*/}

            {/*    <MedicalInsights/>*/}
            {/*</DefaultStyledContainer>*/}

            {/*<ApproachSection>*/}
            {/*    <ApproachGridBackground/>*/}
            {/*    <ApproachContainer>*/}
            {/*        {APPROACH_CARD.map((approach, index) => (*/}
            {/*            <ApproachCard*/}
            {/*                key={index}*/}
            {/*                imagePath={approach.imagePath}*/}
            {/*                title={approach.title}*/}
            {/*                description={approach.description}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </ApproachContainer>*/}
            {/*</ApproachSection>*/}

            <DefaultStyledBox sx={{background: "linear-gradient(180deg, #f5f7ff 10%,  #ffffff 100%)"}}>
                <DefaultStyledContainer>
                    <Typography variant="h5" gutterBottom sx={{marginTop: 20}}>
                        <TitleWrapper>
                            <RegularTitle>
                                {DISCLAIMER_CONTENT.title.regular}
                            </RegularTitle>
                            <HighlightedTitle>
                                <LinearGradient gradient={['to right', '#4066FF ,#FF68F0']}>
                                    <GradientWrapper>
                                        {" "}{DISCLAIMER_CONTENT.title.highlighted}
                                    </GradientWrapper>
                                </LinearGradient>
                            </HighlightedTitle>
                        </TitleWrapper>
                    </Typography>

                    <Typography variant="body1" color="text.secondary" sx={{fontSize: 24, marginTop: 10, textAlign: 'center', color: '#000000'}}>
                        {DISCLAIMER_CONTENT.description.firstString}
                    </Typography>
                </DefaultStyledContainer>
            </DefaultStyledBox>


            {/*<DisclaimerContent>*/}
            {/*    <Disclaimer {...DISCLAIMER_CONTENT}/>*/}
            {/*</DisclaimerContent>*/}

            {/*<DefaultStyledContainer maxWidth="lg">*/}
            {/*    {HOW_DOES_IT_WORK_CARD.map((hdw, index) => (*/}
            {/*        <HowDoesItWork*/}
            {/*            key={index}*/}
            {/*            title={hdw.title}*/}
            {/*            description={hdw.description}*/}
            {/*            videoPath={hdw.videoPath}/>*/}
            {/*    ))}*/}
            {/*</DefaultStyledContainer>*/}

            <ExamplesContainer>
                {/*<PricingContainer/>*/}
                {EXAMPLES_CARD.map((card, index) => (
                    <ExamplesCard
                        key={index}
                        title={card.title}
                        buttons={card.buttons}
                    />
                ))}
            </ExamplesContainer>

            {/*<TestimonialSection>*/}
            {/*    <FeedbackGridBackground/>*/}
            {/*    <FeedbackCard {...FEEDBACK_CARDS[0]} />*/}
            {/*</TestimonialSection>*/}

            <DefaultStyledBox>
                {QUERY_TIP.map((query_tip, index) => (
                    <QueryTip
                        key={index}
                        title={query_tip.title}
                        carousel_content={query_tip.carousel_content}
                    />
                ))}
            </DefaultStyledBox>

            <DefaultStyledBox>
                <FormStyledContainer>
                    {TRIAL_FORM.map((form, index) => (
                        <TrialForm
                            key={index}
                            title={form.title}
                            button={form.button}
                        />
                    ))}
                </FormStyledContainer>
            </DefaultStyledBox>

                {PARTNERS_CONTENT.map((partners, index) => (
                    <Partners
                        key={index}
                        title={partners.title}
                        regular={partners.regular}
                        carousel_content={partners.carousel_content}
                    />
                ))}

            {/*<BusinessCardContainer>*/}
            {/*    <BusinessCard {...BUSINESS_CARD} />*/}
            {/*</BusinessCardContainer>*/}
        </Box>
    );
};

export default Home;