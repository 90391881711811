import {ReportsPageConfig} from "../types/reports.type";

export const REPORTS_PAGE_CONFIG: ReportsPageConfig = {
    title: 'Your Dilemmas',
    description: 'Access and review all dilemmas',
    emptyStateMessage: 'No dilemmas available at the moment',
    cardConfig: {
        dateFormat: 'MMM dd, yyyy',
        statusLabels: {
            draft: 'In Work',
            published: 'Published',
        },
        statusColors: {
            draft: '#FFA726',      // Orange
            published: '#66BB6A',  // Green
        }
    }
};
