import axios from 'axios';
import {S3_API_ENDPOINTS} from "../../constants/s3.constants";

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001/api';

export const s3Api = {
    async listFiles(userId: string): Promise<any> {
        const response = await axios.post(
            `${API_URL}${S3_API_ENDPOINTS.LIST}`,
            {
                prefix: userId
            }
        );
        return response.data;
    },

    async getFile(key: string): Promise<any> {
        const encodedKey = encodeURIComponent(key);
        const response = await axios.get(
            `${API_URL}${S3_API_ENDPOINTS.GET_FILE.replace(':key', encodedKey)}`
        );
        return response.data;
    }
};