import React from 'react';
import {
    Box,
    Grid2,
    TextField,
    Button,
    Typography,
    Container,

} from '@mui/material';
import {styled} from '@mui/material/styles';
import {LinearGradient} from "react-text-gradients";
import {GradientWrapper} from "../../../components/shared/TextGradientWarper";
import ReCAPTCHA from "react-google-recaptcha";
import {TrialFormProps} from "../../../types/home.types";
import {useRegister} from "../../../hooks/useRegister";
import {RegistrationFormData} from "../../../types/registration.types";
import {RegisterUser} from "../../../types/auth.types";
import RegistrationForm from "../../registration/forms/RegistrationForm";
import {LANDING_REGISTRATION_CONFIG} from "../../../constants/registration.constants";
import ConfirmationForm from "../../registration/forms/ConfirmationForm";


const FormContainer = styled(Container)(({theme}) => ({
    padding: theme.spacing(8, 0),

}));


const TextContent = styled(Box)(({theme}) => ({
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
        paddingRight: 0,
        marginBottom: theme.spacing(4),
    },
}));

const TrialForm: React.FC<TrialFormProps> = ({title, button}) => {
    const Icon = button.icon;

    const {
        register,
        confirmRegistration,
        resendConfirmationCode,
        isLoading,
        error,
        isConfirmationRequired,
        registeredEmail
    } = useRegister();

    const handleSubmit = async (formData: RegistrationFormData) => {
        const registrationData: RegisterUser = {
            email: formData.email,
            password: formData.password,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            professionalAffiliation: formData.professionalAffiliation.toString(),
            title: formData.title.toString(),
            isCertifiedMedical: formData.isCertifiedMedical.toString(),
            agreeToPrivacyPolicy: formData.agreeToPrivacyPolicy.toString()
        };

        await register(registrationData);
    };

    const handleConfirmation = async (code: string) => {
        await confirmRegistration({
            email: registeredEmail,
            code
        });
    };

    const handleResendCode = async () => {
        await resendConfirmationCode(registeredEmail);
    };


    return (
        <FormContainer maxWidth="lg">
            <Grid2 container spacing={2}>
                {/* Left side - Text content */}
                <Grid2 size={{xs:12, md:6}}>
                    <TextContent>
                        <Typography variant="h2" gutterBottom>
                            {title.regular.map((text, index) => (
                                <React.Fragment key={index}>
                                    {text}<br/>
                                </React.Fragment>
                            ))}
                            <GradientWrapper>
                                <LinearGradient gradient={['to right', '#4066FF ,#FF68F0']}>
                                    <span style={{fontWeight: 700}}>{title.highlighted}</span>
                                </LinearGradient>
                            </GradientWrapper>
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            {title.description}
                        </Typography>
                    </TextContent>
                </Grid2>

                {/* Right side - Form */}
                <Grid2 size={{xs:12, md:6}}>

                    {!isConfirmationRequired ? (
                        <RegistrationForm
                            config={LANDING_REGISTRATION_CONFIG}
                            onSubmit={handleSubmit}
                            isLoading={isLoading}
                            error={error}
                        />
                    ) : (
                        <ConfirmationForm
                            onSubmit={handleConfirmation}
                            onResendCode={handleResendCode}
                            isLoading={isLoading}
                            error={error}
                            email={registeredEmail}
                        />

                    )}
                    <Box sx={{mt: 2, mb: 3}}>
                        <ReCAPTCHA sitekey="6Lf3w4IqAAAAADcIHzaoiBRDyDIoQDWCIARtsmhe"/>
                    </Box>

                </Grid2>
            </Grid2>
        </FormContainer>
    );
};

export default TrialForm;