import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RouteConfig } from './types/route.types';

import Home from './pages/main/Home';
import Login from "./pages/login/Login";
import Registration from "./pages/registration/Registration";
import Dashboard from "./pages/dashboard/Dashboard";
import Support from "./pages/dashboard/sub-pages/support/Support";
import DashboardWrapper from "./app-layouts/DashboardWrapper";
import Reports from "./pages/dashboard/sub-pages/reports/Reports";
import ReportViewer from "./pages/dashboard/sub-pages/reports/components/ReportViewer";
import {useAuth} from "./hooks/useAuth";
import PrivacyPolicy from "./pages/privacy-terms/PrivacyPolicy";
import TermsOfUse from "./pages/privacy-terms/TermsOfUse";

const publicRoutes: RouteConfig[] = [
    {
        path: '/',
        component: Home,
        title: 'Home'
    },
    {
        path: '/login',
        component: Login,
        title: 'Welcome to Medint!'
    },
    {
        path: '/register',
        component: Registration,
        title: 'Register'
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        title: 'Privacy Policy'
    },
    {
        path: '/terms-and-conditions',
        component: TermsOfUse,
        title: 'Terms Of Use'
    }
];


const AppRoutes: React.FC = () => {
    const {isAuthenticated} = useAuth()

    return (
        <Routes>
            {publicRoutes.map(({ path, component: Component }) => (
                <Route
                    key={path}
                    path={path}
                    element={<Component />}
                />
            ))}

            {/* Dashboard Routes */}
            <Route
                path="/dashboard"
                element={
                    isAuthenticated ? (
                        <DashboardWrapper />
                    ) : (
                        <Navigate to="/login" replace state={{ from: '/dashboard' }} />
                    )
                }
            >
                <Route index element={<Dashboard />} />
                <Route path="support" element={<Support />} />
                <Route path="reports" element={<Reports />} />
                <Route path="reports/:reportId" element={<ReportViewer />} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRoutes;