import {jwtDecode} from 'jwt-decode';

interface DecodedToken {
    exp: number;
    user: {
        email: string;
        phone_number: string;
        family_name: string;
        given_name: string;
        title: string;
        professionalAffil: string;
        isCertifiedMedical: string;
        agreeToPrivacyPolicy: string;
    };
}
export const isValidToken = (token: string | null): boolean => {
    if (!token) return false;

    try {
        const decoded = jwtDecode<DecodedToken>(token);
        const currentTime = Date.now() / 1000;
        console.log('Token validation:', {
            expirationTime: decoded.exp,
            currentTime,
            isValid: decoded.exp > currentTime
        });

        return decoded.exp > currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return false;
    }
};

export const getStoredAuthState = () => {
    try {
        const token = sessionStorage.getItem('auth_token');
        const userData = localStorage.getItem('user_data');

        // Check if both token and userData exist
        if (!token || !userData) {
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }

        // Validate token format (assuming JWT)
        const isTokenValid = token.split('.').length === 3;
        if (!isTokenValid) {
            // Clear invalid token data
            localStorage.removeItem('auth_token');
            localStorage.removeItem('user_data');
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }

        // Check token expiration
        try {
            const tokenPayload = JSON.parse(atob(token.split('.')[1]));
            const isTokenExpired = tokenPayload.exp * 1000 < Date.now();

            if (isTokenExpired) {
                // Clear expired token data
                localStorage.removeItem('auth_token');
                localStorage.removeItem('user_data');
                return {
                    user: null,
                    token: null,
                    isAuthenticated: false
                };
            }
        } catch (tokenError) {
            console.error('Error parsing token:', tokenError);
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }

        // Parse user data with error handling
        let parsedUserData;
        try {
            parsedUserData = JSON.parse(userData);
        } catch (parseError) {
            console.error('Error parsing user data:', parseError);
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }

        // All checks passed, return authenticated state
        return {
            isAuthenticated: true,
            token,
            user: parsedUserData
        };
    } catch (error) {
        console.error('Authentication state error:', error);
        return {
            user: null,
            token: null,
            isAuthenticated: false
        };
    }
};