import React from 'react';
import { Box } from '@mui/material';
import { RegistrationHeaderProps } from '../../../types/registration.types';

const RegistrationHeader: React.FC<RegistrationHeaderProps> = ({ imagePath }) => {
    return (
        <Box sx={{ mb: 4 }}>
            <img src={imagePath} alt="Medint Logo" style={{ maxWidth: '200px', height: 'auto' }} />
        </Box>
    );
};

export default RegistrationHeader;