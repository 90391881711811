import React, {useEffect} from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoginHeader from './components/LoginPageHeader';
import LoginForm from './forms/LoginForm';
import LoginFooter from './components/LoginPageFooter';
import { LoginFormData } from '../../types/login.types';
import {LOGIN_PAGE_CONFIG} from "../../constants/login.constants";
import {useAuth} from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import Loading from '../../components/shared/LoadingCircle'
import {getStoredAuthState} from "../../services/auth/utils/jwt";

const LoginContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(8)
}));

const LoginPaper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
}));

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { login, isLoading, error } = useAuth();

    useEffect(() => {
        const checkAuthStatus = () => {
            const authState = getStoredAuthState();
            if (authState.isAuthenticated) {
                navigate('/dashboard');
            }
        };
        checkAuthStatus();
    }, [navigate]);

    const handleSubmit = async (data: LoginFormData) => {
        const success = await login({
            email: data.email,
            password: data.password,
            rememberMe: data.rememberMe
        });

        if (success) {
            navigate('/dashboard');
        }
    };

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <LoginContainer maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <LoginPaper>
                    <LoginHeader {...LOGIN_PAGE_CONFIG.header} />
                    <LoginForm
                        config={LOGIN_PAGE_CONFIG}
                        onSubmit={handleSubmit}
                        isLoading={isLoading}
                        error={error}
                    />
                    <LoginFooter {...LOGIN_PAGE_CONFIG.footer} />
                </LoginPaper>
            </Box>
        </LoginContainer>
    );
};


export default Login;