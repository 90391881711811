import { useState } from 'react';
import { ResearchQuery, ResearchActivity, ResearchProgress } from '../types/dashboard.types';
import {crmApi} from "../services/crm/crm.service";

export const useCrm = () => {
    const [activities, setActivities] = useState<ResearchActivity[]>([]);
    const [researchProgress, setResearchProgress] = useState<ResearchProgress>({
        status: 'GATHERING',
        currentStep: 1,
        totalSteps: 4,
        startTime: new Date().toISOString()
    });

    const handleQuerySubmit = async (query: ResearchQuery) => {
        try {
            const newActivity: ResearchActivity = {
                id: Date.now().toString(),
                query: query.query,
                status: 'GATHERING',
                createdAt: new Date().toISOString()
            };

            setActivities(prev => [newActivity, ...prev]);
            setResearchProgress({
                status: 'GATHERING',
                currentStep: 1,
                totalSteps: 4,
                startTime: new Date().toISOString()
            });

            await crmApi.submitQuery(query);
        } catch (error) {
            console.error('Failed to submit query:', error);
            // Handle error state here
        }
    };

    return {
        activities,
        researchProgress,
        handleQuerySubmit
    };
};