import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {PolicyPageProps} from "../../../types/policy.types";

const PageContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0),
    minHeight: '100vh',
}));

const ContentContainer = styled(Container)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.95)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(6),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(6),
    '&:last-child': {
        marginBottom: 0,
    },
}));

const PolicyPage: React.FC<PolicyPageProps> = ({
                                                   sections,
                                                   lastUpdated,
                                                   pageTitle,
                                                   backgroundGradient = 'linear-gradient(110deg, #195AF1 10%, #FF7376 100%)'
                                               }) => {
    return (
        <PageContainer>
            <ContentContainer maxWidth="lg">
                <Typography variant="h2" component="h1" gutterBottom>
                    {pageTitle}
                </Typography>

                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Last Updated: {lastUpdated}
                </Typography>

                {sections.map((section, index) => (
                    <Section key={index}>
                        <Typography variant="h5" gutterBottom>
                            {section.title}
                        </Typography>

                        {section.content.map((content, contentIndex) => (
                            <Box key={contentIndex} mb={4}>
                                <Typography variant="h4" gutterBottom>
                                    {content.heading}
                                </Typography>

                                {content.paragraphs.map((paragraph, paraIndex) => (
                                    <Typography
                                        key={paraIndex}
                                        variant="body1"
                                        paragraph
                                    >
                                        {paragraph}
                                    </Typography>
                                ))}

                                {content.subSections?.map((sub, subIndex) => (
                                    <Box key={subIndex} ml={2} mt={2}>
                                        <Typography variant="h5" gutterBottom>
                                            {sub.heading}
                                        </Typography>

                                        {sub.paragraphs.map((para, paraIndex) => (
                                            <Typography
                                                key={paraIndex}
                                                variant="body1"
                                                paragraph
                                            >
                                                {para}
                                            </Typography>
                                        ))}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Section>
                ))}
            </ContentContainer>
        </PageContainer>
    );
};

export default PolicyPage;