import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Alert,
    CircularProgress
} from '@mui/material';

interface ConfirmationFormProps {
    onSubmit: (code: string) => Promise<void>;
    onResendCode: () => Promise<void>;
    isLoading: boolean;
    error: string | null;
    email: string;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
                                                               onSubmit,
                                                               onResendCode,
                                                               isLoading,
                                                               error,
                                                               email
                                                           }) => {
    const [code, setCode] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSubmit(code);
    };

    const handleResendCode = async () => {
        setResendDisabled(true);
        setCountdown(30); // Disable resend for 30 seconds
        await onResendCode();

        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setResendDisabled(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
                Verify Your Email
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                We've sent a verification code to {email}. Please enter it below.
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <TextField
                margin="normal"
                required
                fullWidth
                label="Verification Code"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                autoFocus
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{ mt: 3, mb: 2 }}
            >
                {isLoading ? <CircularProgress size={24} /> : 'Verify Email'}
            </Button>

            <Button
                fullWidth
                variant="text"
                onClick={handleResendCode}
                disabled={resendDisabled}
                sx={{ mt: 1 }}
            >
                {resendDisabled
                    ? `Resend code in ${countdown}s`
                    : 'Resend verification code'}
            </Button>
        </Box>
    );
};

export default ConfirmationForm;