import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {LoginHeaderProps} from "../../../types/login.types";

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
}));

const LoginHeader: React.FC<LoginHeaderProps> = ({ imagePath }) => {
    return (
        <HeaderContainer>
            <img
                src={imagePath}
                alt="Medint Logo"
                style={{ width: '15em', height: 'auto', marginBottom: '2rem' }}
            />
        </HeaderContainer>
    );
};

export default LoginHeader;