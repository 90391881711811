import React, { useState } from 'react';
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    Grid2,
    Link,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel
} from '@mui/material';
import { RegistrationFormProps, RegistrationFormData } from '../../../types/registration.types';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const RegistrationForm: React.FC<RegistrationFormProps> = ({ config, onSubmit, isLoading = false }) => {
    const [formData, setFormData] = useState<RegistrationFormData>({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        title: '',
        phoneNumber: '',
        professionalAffiliation: '',
        isCertifiedMedical: false,
        agreeToPrivacyPolicy: false
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '1rem' }}>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
                {config.title.main}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary" paragraph>
                {config.title.description}
            </Typography>

            <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">How Would You Like to be Addressed</FormLabel>
                <RadioGroup
                    row
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                >
                    {config.labels.titleOptions.map((title) => (
                        <FormControlLabel
                            key={title}
                            value={title}
                            control={<Radio />}
                            label={title}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <Grid2 container spacing={2}>
                <Grid2 size={{xs: 12, sm:6 }}>
                    <TextField
                        name="firstName"
                        required
                        fullWidth
                        label="First Name"
                        placeholder={config.placeholders.firstName}
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm:6 }}>
                    <TextField
                        name="lastName"
                        required
                        fullWidth
                        label="Last Name"
                        placeholder={config.placeholders.lastName}
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextField
                        name="email"
                        required
                        fullWidth
                        label="Email Address"
                        type="email"
                        placeholder={config.placeholders.email}
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextField
                        name="password"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        placeholder={config.placeholders.password}
                        value={formData.password}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <PhoneInput
                        name="phoneNumber"
                        placeholder={config.placeholders.phoneNumber}
                        value={formData.phoneNumber}
                        onChange={(phoneNumber) => setFormData({ ...formData, phoneNumber })}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextField
                        name="professionalAffiliation"
                        fullWidth
                        label="Professional Affiliation"
                        placeholder={config.placeholders.professionalAffiliation}
                        value={formData.professionalAffiliation}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isCertifiedMedical"
                                checked={formData.isCertifiedMedical}
                                onChange={handleChange}
                                color="primary"
                            />
                        }
                        label={config.labels.certifiedMedical}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => console.log('Show privacy policy')}
                    >
                        {config.labels.showPrivacyPolicy}
                    </Link>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="agreeToPrivacyPolicy"
                                checked={formData.agreeToPrivacyPolicy}
                                onChange={handleChange}
                                color="primary"
                                required
                            />
                        }
                        label={config.labels.privacyPolicy}
                    />
                </Grid2>
            </Grid2>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading || !formData.agreeToPrivacyPolicy}
                sx={{ mt: 3, mb: 2 }}
                endIcon={config.button.icon && <config.button.icon />}
            >
                {config.button.text}
            </Button>
        </form>
    );
};

export default RegistrationForm;