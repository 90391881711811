import { ArrowForward } from '@mui/icons-material';
import {LandingRegistrationPageConfig, RegistrationPageConfig} from '../types/registration.types';

export const REGISTRATION_PAGE_CONFIG: RegistrationPageConfig = {
    title: {
        main: "Get Started With Medint",
        description: "Medint answers complex clinical dilemmas for physicians based on academic literature and an AI that is being developed"
    },
    button: {
        text: "Register",
        icon: ArrowForward,
        onClick: () => console.log('Registration clicked')
    },
    footer: {
        description: "Already have an account?",
        href: "Log in to the Medint community"
    },
    placeholders: {
        email: "youremail@gmail.com",
        password: "••••••••••••",
        firstName: "First Name",
        lastName: "Last Name",
        phoneNumber: "Phone Number",
        professionalAffiliation: "Professional Affiliation"
    },
    labels: {
        titleOptions: ["Dr.", "Prof.", "Mr.", "Ms."],
        certifiedMedical: "I'm a certified medical professional",
        privacyPolicy: "Please confirm that you agree to our privacy policy",
        showPrivacyPolicy: "Show privacy policy"
    },
    header: {
        imagePath: "/medint-logo-full-transparent.png"
    }
};

export const LANDING_REGISTRATION_CONFIG: LandingRegistrationPageConfig = {
    title: {
        main: "",
        description: ""
    },
    button: {
        text: "Register",
        icon: ArrowForward,
        onClick: () => console.log('Registration clicked')
    },
    footer: {
        description: "Already have an account?",
        href: "Log in to the Medint community"
    },
    placeholders: {
        email: "youremail@gmail.com",
        password: "••••••••••••",
        firstName: "First Name",
        lastName: "Last Name",
        phoneNumber: "Phone Number",
        professionalAffiliation: "Professional Affiliation"
    },
    labels: {
        titleOptions: ["Dr.", "Prof.", "Mr.", "Ms."],
        certifiedMedical: "I'm a certified medical professional",
        privacyPolicy: "Please confirm that you agree to our privacy policy",
        showPrivacyPolicy: "Show privacy policy"
    },
    header: {
        imagePath: "/medint-logo-full-transparent.png"
    }
};