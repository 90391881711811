import React, {useState} from 'react';
import {Button, Paper, Typography, Box, Container, Modal, IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';

import {ExamplesCard as ExamplesCardType} from "../../../types/home.types";
import {LinearGradient} from "react-text-gradients";
import {GradientWrapper} from "../../../components/shared/TextGradientWarper";
import PdfViewer from "../../../utils/PdfViewer";


const RegularTitle = styled('span')(({theme}) => ({
    color: theme.palette.text.primary,
    fontSize: '2.5rem',
    fontWeight: 700,
}));

const HighlightedTitle = styled('span')(({theme}) => ({
    fontSize: '2.5rem',
    fontWeight: 700,
    display: 'inline-block',
}));

const ButtonsContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
}));

const StyledButton = styled(Button)(({theme}) => ({
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
    },
}));


const ModalContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    borderRadius: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
}));

const ModalHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ModalBody = styled(Box)({
    flex: 1,
    overflow: 'hidden',
});

const ExamplesCard: React.FC<ExamplesCardType> = ({title, buttons}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState<string | null>(null);

    const handleOpenModal = (pdfUrl: string) => {
        setSelectedPdfUrl(pdfUrl);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPdfUrl(null);
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{textAlign: 'center', mb: 6}}>
                <Typography variant="h2" gutterBottom>
                    <RegularTitle>
                        {title.regular}{' '}
                    </RegularTitle>
                    <GradientWrapper>
                        <LinearGradient gradient={['to right', '#4066FF ,#FF68F0']}>
                            <HighlightedTitle>
                                {title.highlighted}
                            </HighlightedTitle>
                        </LinearGradient>
                    </GradientWrapper>
                </Typography>
            </Box>

            <ButtonsContainer>
                {buttons.map((button, index) => {
                    const Icon = button.icon;
                    return (
                        <StyledButton
                            key={index}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (button.onClick) button.onClick();
                                handleOpenModal(button.href);
                            }}
                        >
                            <Icon />
                            {button.text}
                        </StyledButton>
                    );
                })}
            </ButtonsContainer>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="pdf-viewer-modal"
            >
                <ModalContent>
                    <ModalHeader>
                        <IconButton onClick={handleCloseModal} size="large">
                            <CloseIcon />
                        </IconButton>
                    </ModalHeader>
                    <ModalBody>
                        {selectedPdfUrl && <PdfViewer url={selectedPdfUrl} />}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default ExamplesCard;