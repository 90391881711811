import React, {useState} from 'react';
import {Box, IconButton, List, ListItem, ListItemText, Button, Typography, Paper} from '@mui/material';
import { Clear as ClearIcon, ArrowForward } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const FileUploadComponent = ({ queryData, handleFileChange, removeFile, handleSubmit }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        const event = { target: { files } };
        handleFileChange(event);
    };

    return (
        <Box sx={{ mt: 2, width: '100%' }}>
            {/* Upload Area */}
            <Paper
                component="label"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                sx={{
                    border: '2px dashed',
                    borderColor: isDragging ? 'primary.main' : 'grey.300',
                    borderRadius: 2,
                    p: 3,
                    cursor: 'pointer',
                    backgroundColor: isDragging ? 'rgba(25, 118, 210, 0.04)' : 'background.paper',
                    transition: 'all 0.2s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1
                }}
            >
                <input
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileChange}
                />
                <CloudUploadIcon
                    sx={{
                        fontSize: 48,
                        color: isDragging ? 'primary.main' : 'text.secondary'
                    }}
                />
                <Typography variant="body1" color="text.secondary" align="center">
                    Drag and drop files here or click to browse
                </Typography>
            </Paper>

            {/* File List */}
            {queryData.files && queryData.files.length > 0 && (
                <Paper sx={{ mt: 2, maxHeight: '300px', overflow: 'auto' }}>
                    <List dense>
                        {queryData.files.map((file, index) => (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() => removeFile(index)}
                                        size="small"
                                        sx={{ color: 'error.light' }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                }
                                sx={{
                                    borderBottom: index !== queryData.files.length - 1 ? '1px solid' : 'none',
                                    borderColor: 'divider',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="body2" noWrap>
                                            {file.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="caption" color="text.secondary">
                                            {`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    endIcon={<ArrowForward />}
                    disabled={!queryData.files || queryData.files.length === 0}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default FileUploadComponent;