import axios from 'axios';
import {
    AuthApiResponse, ConfirmationResponse,
    ConfirmRegistration,
    LoginCredentials,
    RegisterUser,
    RegistrationResponse
} from "../../types/auth.types";
import {AUTH_API_ENDPOINTS} from "../../constants/auth.constants.";


const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001/api';

export const authApi = {
    async login(credentials: LoginCredentials): Promise<AuthApiResponse> {
        const response = await axios.post<AuthApiResponse>(
            `${API_URL}${AUTH_API_ENDPOINTS.LOGIN}`,
            credentials
        );
        return response.data;
    },


    async register(userData: RegisterUser): Promise<AuthApiResponse<RegistrationResponse>> {
        const response = await axios.post<AuthApiResponse<RegistrationResponse>>(
            `${API_URL}${AUTH_API_ENDPOINTS.REGISTER}`,
            userData
        );
        return response.data;
    },

    async confirmRegistration(data: ConfirmRegistration): Promise<AuthApiResponse<ConfirmationResponse>> {
        const response = await axios.post<AuthApiResponse<ConfirmationResponse>>(
            `${API_URL}${AUTH_API_ENDPOINTS.CONFIRMATION}`,
            data
        );
        return response.data;
    },

    async resendConfirmationCode(email: string): Promise<AuthApiResponse<ConfirmationResponse>> {
        const response = await axios.post<AuthApiResponse<ConfirmationResponse>>(
            `${API_URL}${AUTH_API_ENDPOINTS.RESEND_CONFIRMATION}`,
            {email}
        );
        return response.data;
    },

    async logout(): Promise<AuthApiResponse<void>> {
        const response = await axios.post<AuthApiResponse<void>>(
            `${API_URL}${AUTH_API_ENDPOINTS.LOGOUT}`,
        );
        return response.data;
    }
};