import {useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { login, logout, clearError } from '../store/slices/authSlice';
import {LoginCredentials} from "../types/auth.types";


export const useAuth = () => {
    const dispatch = useDispatch();
    const authState = useSelector((state: RootState) => state.auth);


    const handleLogin = useCallback(
        async (credentials: LoginCredentials) => {
            try {
                const fullCredentials: LoginCredentials = {
                    ...credentials,
                    rememberMe: credentials.rememberMe ?? true // Default to true if not specified
                };

                const result = await dispatch(login(fullCredentials)).unwrap();
                console.log('Login result:', result);
                return true;
            } catch (error) {
                console.error('Login error:', error);
                return false;
            }
        },
        [dispatch]
    );

    const handleLogout = useCallback(async () => {
        try {
            await dispatch(logout()).unwrap();
            return true;
        } catch (error) {
            console.error('Logout error:', error);
            return false;
        }
    }, [dispatch]);

    const handleClearError = useCallback(() => {
        dispatch(clearError());
    }, [dispatch]);

    return {
        user: authState.user,
        isAuthenticated: authState.isAuthenticated,
        isLoading: authState.isLoading,
        error: authState.error,
        login: handleLogin,
        logout: handleLogout,
        clearError: handleClearError,
    };
};