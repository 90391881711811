import React from 'react';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter as Router} from 'react-router-dom';
import {theme} from './themes/theme';
import AppRoutes from './AppRoutes';
import LayoutWrapper from "./app-layouts/LayoutWrapper";
import AppProviderWrapper from './AppProvider';
import {store} from "./store/store";


const App: React.FC = () => {
    return (
        <Provider store={store}>
            <Router>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AppProviderWrapper>
                        <LayoutWrapper>
                            <AppRoutes/>
                        </LayoutWrapper>
                    </AppProviderWrapper>
                </ThemeProvider>
            </Router>
        </Provider>

    );
};

export default App;
