import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {AuthState, LoginCredentials} from "../../types/auth.types";
import {authApi} from "../../services/auth/auth.services";
import {getStoredAuthState} from "../../services/auth/utils/jwt";

console.log('Getting stored state...', getStoredAuthState());


const initialState: AuthState = {
    ...getStoredAuthState(),
    isLoading: false,
    error: null
};

console.log('Initial state:', initialState);



export const login = createAsyncThunk(
    'auth/login',
    async (credentials: LoginCredentials, { rejectWithValue }) => {
        try {
            const response = await authApi.login(credentials);
            console.log("login response", response)
            if (credentials.rememberMe) {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('user_data', JSON.stringify(response.data.user));

            } else {
                sessionStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('user_data', JSON.stringify(response.data.user));

            }
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Login failed');
        }
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            await authApi.logout();
            localStorage.removeItem('auth_token');
            sessionStorage.removeItem('auth_token');
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Logout failed');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                console.log('Login fulfilled:', action.payload.data);

                state.isLoading = false;
                state.isAuthenticated = true;
                state.token = action.payload.data.token;
                state.user = {
                    email: action.payload.data.user.email,
                    phoneNumber: action.payload.data.user.phone_number,
                    familyName: action.payload.data.user.family_name,
                    givenName: action.payload.data.user.given_name,
                    title: action.payload.data.user.title,
                    professionalAffiliation: action.payload.data.user.professionalAffil,
                    isCertifiedMedical: action.payload.data.user.isCertifiedMedical === 'true',
                    agreeToPrivacyPolicy: action.payload.data.user.agreeToPrivacyPolicy === 'true'
                };

                console.log('Updated state:', state);
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.token = null;
                state.isAuthenticated = false;
            });
    },
});

export const { clearError } = authSlice.actions;
export default authSlice.reducer;
