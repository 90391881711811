import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import {FooterSocialProps} from "../../../types/footer.types";



const FooterSocial: React.FC<FooterSocialProps> = ({
                                                       social,
                                                       iconColor = 'primary'
                                                   }) => {
    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
                {social.title}
            </Typography>
            <Box sx={{
                display: 'flex',
                gap: 1,
                '& .MuiIconButton-root': {
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    }
                }
            }}>
                {social.icons.map(({ id, icon: Icon, href, label }) => (
                    <IconButton
                        key={id}
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        color={iconColor}
                        aria-label={label}
                        sx={{
                            '&:hover': {
                                backgroundColor: `${iconColor}.lighter`,
                            }
                        }}
                    >
                        <Icon />
                    </IconButton>
                ))}
            </Box>
        </Box>
    );
};

export default FooterSocial;