import React from 'react';
import { useLocation } from 'react-router-dom';
import MainLayout from './MainLayout';
import AuthLayout from './AuthLayout';
import { HIDDEN_LAYOUT_ROUTES } from '../constants/routes.constants';

interface LayoutWrapperProps {
    children: React.ReactNode;
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
    const location = useLocation();
    const shouldShowLayout = !HIDDEN_LAYOUT_ROUTES.includes(location.pathname);
    const isDashboardRoute = location.pathname.startsWith('/dashboard');

    if (isDashboardRoute) {
        return <>{children}</>;
    }

    return shouldShowLayout ? (
        <MainLayout>{children}</MainLayout>
    ) : (
        <AuthLayout>{children}</AuthLayout>
    );
};

export default LayoutWrapper;