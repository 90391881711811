import axios from 'axios';
import {ResearchQuery} from "../../types/dashboard.types";
import {CRM_API_ENDPOINTS} from "../../constants/crm.constants";

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001/api';

export const crmApi = {
    async submitQuery(query: ResearchQuery): Promise<any> {
        const userData = JSON.parse(localStorage.getItem('user_data') || '{}');

        const formData = new FormData();

        formData.append('query', query.query);
        formData.append('patientSummary', query.patientSummary);
        formData.append('fullName', `${userData.given_name} ${userData.family_name}`);
        formData.append('email', userData.email);
        formData.append('userId', userData.sub);

        if (query.files && query.files.length > 0) {
            query.files.forEach(file => {
                formData.append('files', file);
            });
        }

        const response = await axios.post(
            `${API_URL}${CRM_API_ENDPOINTS.DASHBOARD}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
        return response.data;
    }
};