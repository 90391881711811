import React from 'react';
import { Container, Box } from '@mui/material';
import SupportForm from './forms/SupportForm';

const Support: React.FC = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 4 }}>
                <SupportForm />
            </Box>
        </Container>
    );
};

export default Support;