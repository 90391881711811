import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const NavButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));

const Header: React.FC = () => {
    return (
        <AppBar position="static" color={"transparent"}>
            <StyledToolbar>
                <RouterLink to="/">
                    <img src="/medint-logo-full-transparent.png" alt="Logo" height="50" />
                </RouterLink>

                <Box>
                    <Button
                        component={RouterLink}
                        to="/login"
                        variant="outlined"
                        color="inherit"
                        sx={{ ml: 2 }}
                    >
                        Login
                    </Button>
                </Box>
            </StyledToolbar>
        </AppBar>
    );
};
export default Header;