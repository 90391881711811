import {PolicyPageProps} from "../types/policy.types";

export const PRIVACY_POLICY: PolicyPageProps = {
    pageTitle: "Privacy Policy",
    lastUpdated: "December 12, 2024",
    backgroundGradient: "linear-gradient(110deg, #195AF1 10%, #FF7376 100%)",
    sections: [
        {
            title: "Introduction",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "This Privacy Policy describes how we collect, use, and handle your personal information when you use our services.",
                        "By using our services, you agree to the collection and use of information in accordance with this policy."
                    ]
                }
            ]
        },
        {
            title: "Information Collection",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We collect the following personal information related to you:",
                        "When filling the ‘contact us’ form available on Our Website, you provide us with the following personal information: your name; email address; phone number; and any additional information you choose to provide Us. Additionally, due to the nature of the Services, you also provide us with Medical Information, specifically whether you have a definitive medical diagnosis.",
                        "When posting comments on the ‘Blog’ section, you may provide Us with additional personal information you choose to disclose to Us, at your sole discretion and sole responsibility.",
                        "When contracting Us to receive the Services, and as part of the Services: (a) you provide us with Customer’s, and to the extent applicable Patient’s, full name, physical and email addresses, phone number and Customer’s payment details; (b) during the Initial Review period, and through the course of the provision of Services, you provide Us with a variety of Medical Information so that We can assess the possibility of providing the Services, and subject to the Initial Review, provide you with the Services; and (c) to the extent that certain Meetings, as detailed in the Medint Services Agreement, will be held between you and Us, and may be recorded, we will collect and store audio or audio-visual recordings of such Meetings.",
                        "When using Our Website, we collect traffic data that your online activities generate, such as IP addresses, browser data, session durations and viewed pages."
                    ],
                }
            ]
        },
        {
            title: "How We Use Personal Information Related to You",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We use personal information related to you for the following purposes:",
                        "To operate and enable the Website and Our Services, send you related updates, notices and announcements, and to improve and customize your experience with the Website and the Services.",
                        "To perform Our Initial Review under the Medint Services Agreement.",
                        "To protect and enforce the rights and interests of our employees, users, suppliers, affiliates, business partners, and our own rights and interests, and to take any action in any case of dispute, or legal proceeding.",
                        "To prevent and remediate fraud, misappropriation, infringements, violations, identity theft, cyber-attacks and other security incidents, illegal activities and misuse or abuse of the Services.",
                        "To comply with any requests and orders of law enforcement agencies and authorities.",
                    ]
                }
            ]
        },
        {
            title: "How We Share Personal Information Related to You",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We do not sell, rent, or lease personal information related to you. We share personal information related to you for the following purposes:",
                        "We will share personal information related to you with third-party service providers, including without limitation, Researchers, to help us operate, manage and protect Our Website and our operations, and to provide the Services. These service providers provide us with services such as analytics, information security, IT support, cloud storage and email transmission.",
                        "We will provide limited access to Medical Information to Researchers who need to know it in order to provide you with the Services. Disclosure of Medical Information to Researchers is subject to strict confidentiality undertakings and is limited to the duration of the provision of the Services.",
                        "We will disclose personal information related to you with or without notice (a) if required by a judicial or administrative order; (b) where required by law; or (c) at our sole discretion, when we deem it necessary to protect the safety of any individual, the general public, or to prevent violations of our rights or interests, or the rights or interests of any third party.",
                        "A merger, acquisition or any other structural change will require us to transfer personal information to another entity as part of the structural change.",
                    ]
                }
            ]
        },
        {
            title: "Aggregated and Analytical Information",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We use Google Analytics and other analytics tools from time to time within Our Website. The privacy practices of third-party service providers are subject to their own policies. Please review Google Analytics’ policy here: https://www.google.com/analytics/learn/privacy.html. Analytics services use personal information for their own purposes. Please review the “Your Choices” section below for opt-out options.",
                        "We may use information, data and materials forming part of the research results and the Research Report, following deletion of all personal information therein, or any other data that might lead to the identification of a Patient, for various purposes, including but not limited to enhance Our Services, train and recruit Our employees and consultants and conducting research.",
                        "We may share such information at Our discretion for lawful business purposes. It has no effect on your privacy, because there is no reasonable way to extract data from the aggregated information that We or others can associate specifically to you.",
                    ]
                }
            ]
        },
        {
            title: "Your Choices",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "You are not required by law to provide Us with the personal information above. Additionally, We provide you with the following choices for controlling personal information related to you that We use:",
                        "At any time, you may opt out of our mailing lists. It may take some time to up to 10 business days for your opt-out request to take effect.",
                        "At any time, you can stop using Our Website, and We will stop collecting any respective personal information from you or about you via the Website. Personal information collected up until such point will continue to be stored by Us, in accordance with the ‘Retention’ section below.",
                        "If you find that personal information related to you is inaccurate, incomplete or not up to date, please provide us with the necessary information to correct it.",
                        "At any time, you can contact Us and request to access the personal information that we keep about you. We will ask you to provide certain credentials to verify your identity. If required under applicable law, we will make good-faith efforts to locate and provide you with a copy of personal information related to you that you request and that resides in our information systems.",
                    ]
                }
            ]
        },
        {
            title: "Retention",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We retain personal information related to you for as long as necessary to fulfill the purposes of using such information under this Privacy Policy, for the provision of Services, and as permitted by law.",
                        "Following the termination of your use of the Website or Services, as applicable, we will retain the Medical Information for legal and archival purposes, and will only use it to the extent disclosure is necessary, in our opinion, in order to perform any of the following actions: (1) compliance with legal procedures, including, without limitation, court orders or compulsory disclosures required by governmental authorities; (2) enforcement of the Terms or the Medint Services Agreement, including resolving any disputes arising out of the use of the Website, or Services, as applicable; or (3) response to customer service inquiries.",
                        "We will keep aggregated non-identifiable information without limitation. We will delete or de-identify potentially identifiable information when we no longer need to process the information in an identifiable form.",
                    ]
                }
            ]
        },
        {
            title: "Communication",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We may communicate with you through e-mail, telephone, video conference, chats, notices or through other means available on the Website. We reserve the right to retain your correspondence with Medint`s customer service and to record phone calls to or from Medint`s customer service with you, for the purpose of responding to Customer inquiries, improving Medint`s customer service, and investigating potential fraud and breaches of the Medint Services Agreement.",
                    ]
                }
            ]
        },
        {
            title: "Cookies; Internet-Related Information",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "Cookies are small files that a web server sends to a user’s device when that user browses online. Your device removes session cookies when you close your browser session. Persistent cookies last for longer periods. You can view the expiry date of each cookie through your browser settings. From time to time, We will use session and persistent cookies and other web-tracking technologies, such as pixel tags and local storage objects, to facilitate the use of the Website features and tools, and to improve users’ experience with Our Website.",
                        "Every browser allows you to manage your cookies preferences. You can block or remove certain or all cookies through your browser settings. To the extent you authorized the use of cookies, Our systems will recognize your browser, track Website usage trends, improve the quality of Our Services, and customize your experience on the Website.",
                        "You can remove or block cookies using the settings in your browser, but in some cases doing so might impact your ability to enjoy certain services or features offered through the Website.",
                        "Google Analytics places its own cookies to provide Us with its analytics services. We do not control these cookies. Please use the Google Analytics Opt-out Browser Add-on, which is available here:  https://tools.google.com/dlpage/gaoptout, or any other opt-out mechanism offered by Google to stop the collection of personal information related to you by Google Analytics."
                    ]
                }
            ]
        },
        {
            title: "International Transfer",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We may store and process personal information on various sites throughout the globe, including in Israel and in sites operated and maintained by cloud-based service providers in the United States.",
                        "If you are a resident in a jurisdiction where the transfer of personal information related to you to another country requires your consent, then you provide Us your freely given, expressed, specific and unambiguous consent for such transfer.",
                    ]
                }
            ]
        },
        {
            title: "Information Security",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "We maintain the confidentiality of personal information related to you and we take reasonable steps to ensure that the information is accurate, complete, current and reliable for its intended use",
                        "We take reasonable measures designed to protect personal information related to you from accidental loss and from unauthorized access, use, alteration or disclosure. However, due to the nature of the Internet, We cannot guarantee that the Website, or any of the information systems we use in relation to the Services are immune from cyberattacks, malicious activities, malfunctions, honest mistakes or other types of abuse and misuse of personal information.",
                    ]
                }
            ]
        },
        {
            title: "Updates",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "From time to time, We will need to update this Privacy Policy. If the updates have minor or no consequences, they will take effect 7 days after we post a notice on our Website, or after we send you a notice through email. Substantial changes will take effect 30 days after we initially post or send our notice. If we need to adapt this policy to legal requirements, the new policy will become effective immediately or as required.",
                        "Until the new policy takes effect, you can choose not to accept it and terminate your use of the Website or Services. Continuing to use the Website or Services after the new policy takes effect means that you agree to the new policy.",
                    ]
                }
            ]
        },
        {
            title: "Contact Us",
            content: [
                {
                    heading: "",
                    paragraphs: [
                        "If you have any questions or concerns about the way We process personal information related to you, please email Us at: support@medint.ai. We will review your query and make a good-faith effort to respond promptly.",
                    ]
                }
            ]
        }
    ]
};