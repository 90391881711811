import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid2, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReportCard from './components/ReportCard';
import { REPORTS_PAGE_CONFIG} from "../../../../constants/reports.constants";
import { useS3 } from '../../../../hooks/useS3';
import LoadingCircle from "../../../../components/shared/LoadingCircle";



const ReportsContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
}));

const Reports: React.FC = () => {
    const navigate = useNavigate();
    const { files, isLoading, error, fetchUserFiles } = useS3();
    const [user, setUser] = useState({
        id: '',
        title: '',
        given_name: '',
        family_name: ''
    });

    useEffect(() => {
        const userData = localStorage.getItem('user_data');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser(parsedUser);
            if (parsedUser.sub) {
                fetchUserFiles(parsedUser.sub);
            }
        }
    }, []);

    const handleReportClick = (reportId: string) => {
        const fileId = reportId.split('/').pop() || reportId; // Get just the filename
        navigate(`/dashboard/reports/${fileId}`);
    };

    const handleDownload = (reportId: string) => {
        const report = files.find(f => f.id === reportId);
        if (report && report.pdfUrl) {
            window.open(report.pdfUrl, '_blank');
        }
    };

    if (isLoading) {
        return <LoadingCircle />;
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography color="error">
                    Error loading files
                </Typography>
            </Box>
        );
    }

    return (
        <ReportsContainer maxWidth="lg">
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {REPORTS_PAGE_CONFIG.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {REPORTS_PAGE_CONFIG.description}
                </Typography>
            </Box>

            <Grid2 container spacing={3}>
                {files.map((file) => (
                    <Grid2 size={{xs:12, sm:6, md:4}} key={file.id}>
                        <ReportCard
                            report={file}
                            onClick={handleReportClick}
                            onDownload={handleDownload}
                        />
                    </Grid2>
                ))}
            </Grid2>

            {files.length === 0 && (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="body1" color="text.secondary">
                        {REPORTS_PAGE_CONFIG.emptyStateMessage}
                    </Typography>
                </Box>
            )}
        </ReportsContainer>
    );
};

export default Reports;