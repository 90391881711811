import React from 'react';
import { Grid2, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FOOTER_CONFIG } from '../../../constants/footer.constants';
import { NavigationLink } from '../../../types/footer.types';

const StyledLink = styled(RouterLink)(({ theme }) => ({
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

const IconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > svg': {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
}));

// Helper component for rendering navigation links
const NavigationLinks: React.FC<{ links: NavigationLink[] }> = ({ links }) => (
    <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
        {links.map(({ label, path }) => (
            <Box component="li" key={label} sx={{ mb: 1 }}>
                <StyledLink to={path}>
                    {label}
                </StyledLink>
            </Box>
        ))}
    </Box>
);

const FooterLinks: React.FC = () => {
    const { company, quickLinks, legalLinks, contact } = FOOTER_CONFIG;

    return (
        <Grid2 container spacing={6}>
            {/* Company Info */}
            <Grid2 size={{ xs:12, md:6}}>
                <Box component={RouterLink} to="/" sx={{ display: 'block', mb: 2 }}>
                    <img
                        src={company.logo.src}
                        alt={company.logo.alt}
                        height={company.logo.height}
                    />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, maxWidth: 400 }}>
                    {company.description}
                </Typography>
            </Grid2>

            {/* Quick Links */}
            <Grid2 size={{sm:3, md:2}}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    {quickLinks.title}
                </Typography>
                <NavigationLinks links={quickLinks.links} />
            </Grid2>

            {/* Legal Links */}
            <Grid2 size={{sm:6, md:2}}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    {legalLinks.title}
                </Typography>
                <NavigationLinks links={legalLinks.links} />
            </Grid2>

            {/* Contact Information */}
            <Grid2 size={{md:2}}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    Contact Us
                </Typography>
                <IconBox>
                    <EmailIcon />
                    <Typography variant="body2" color="text.secondary">
                        {contact.email}
                    </Typography>
                </IconBox>
                <IconBox>
                    <LocationOnIcon />
                    <Typography variant="body2" color="text.secondary">
                        {contact.address}
                    </Typography>
                </IconBox>
            </Grid2>
        </Grid2>
    );
};

export default FooterLinks;