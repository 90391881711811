import {Box, CircularProgress} from "@mui/material";
import Logo from "./medint-logo.svg";


const Loading = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            }}
        >
            <CircularProgress
                size={150}
                sx={{
                    color: 'white',
                    position: 'absolute',
                    width: '100%',
                    height: 'auto',
                }}
                variant="indeterminate"
            />

            <img src={Logo} alt="Logo" style={{width: '150px', height: '150px'}}/>
        </Box>
    );
}

export default Loading