import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
    Dashboard as DashboardIcon,
    Article as QueriesIcon,
    ContactSupport as SupportIcon,
} from '@mui/icons-material';
import { type Navigation } from '@toolpad/core/AppProvider';
import { AppProvider as MuiAppProvider } from '@toolpad/core/react-router-dom';
import {theme} from "./themes/theme";

const NAVIGATION: Navigation = [
    {
        kind: 'header',
        title: 'Main',
    },
    {
        kind: 'page',
        segment: 'dashboard',
        title: 'Dashboard',
        icon: <DashboardIcon />,
        pattern: '/dashboard'
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: '',
    },
    {
        kind: 'page',
        segment: 'reports',
        title: 'My Clinical Dilemmas',
        icon: <QueriesIcon />,
        pattern: '/dashboard/reports',
    },
    {
        kind: 'page',
        segment: 'support',
        title: 'Contact Support',
        icon: <SupportIcon />,
        pattern: '/dashboard/support'
    }
];


interface AppProviderProps {
    children: React.ReactNode;
}

export const AppProviderWrapper: React.FC<AppProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const router = {
        pathname: location.pathname,
        navigate: (url: any) => {
            //Navigation guard
            if (url.startsWith('/dashboard')) {
                navigate(url);
            } else {
                navigate(`/dashboard${url.startsWith('/') ? url : `/${url}`}`);
            }
        },
        searchParams
    };

    return (
        <MuiAppProvider
            navigation={NAVIGATION}
            router={router}
            branding={{
                logo: <img src='/favicon.png' alt = "Medint Favicon"/>,
                title: 'Medint',
            }}
            theme={theme}
        >
            {children}
        </MuiAppProvider>
    );
};

export default AppProviderWrapper;