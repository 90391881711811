import React from 'react';
import { Link, Typography, Box } from '@mui/material';
import { RegistrationFooterProps } from '../../../types/registration.types';

const RegistrationFooter: React.FC<RegistrationFooterProps> = ({ description, href }) => {
    return (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body2">
                {description}{' '}
                <Link href="/login" variant="body2">
                    {href}
                </Link>
            </Typography>
        </Box>
    );
};

export default RegistrationFooter;