import React from 'react';
import {Box, Grid2, Typography, TextField, Button} from '@mui/material';

const FooterSubscribe: React.FC = () => {
    const handleSubscribe = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <Box component="form" onSubmit={handleSubscribe} sx={{my: 3}}>
            <Grid2 container spacing={42} sx={{ alignItems: 'center' }}>
                <Grid2 size={{ xs:12, md:6}}>
                    <Typography variant="h6" gutterBottom>
                        Stay Updated
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        Subscribe to our newsletter to receive the latest medical insights and updates.
                    </Typography>
                </Grid2>

                <Grid2 size={{ xs:12, md:6}}>
                    <Box sx={{display: 'flex', gap: 1}}>
                        <TextField
                            size="small"
                            placeholder="Email address"
                            sx={{flexGrow: 1}}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{whiteSpace: 'nowrap'}}
                        >
                            Subscribe
                        </Button>
                    </Box>
                </Grid2>

            </Grid2>
        </Box>
    );
};

export default FooterSubscribe;
