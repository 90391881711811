import React, { useState, useEffect } from 'react';
import {Grid2, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Button} from '@mui/material';
import {Info as TipIcon} from '@mui/icons-material';
import { DASHBOARD_CONFIG } from '../../constants/dashboard.constants';
import ResearchProgress from './components/ResearchProgress';
import QueryForm from './forms/DoctorQuery';
import {useCrm} from "../../hooks/useCrm";
import {DefaultStyledBox} from "../main/HomeStyles";


const Dashboard: React.FC = () => {
    const { activities, researchProgress, handleQuerySubmit } = useCrm();


    const [user, setUser] = useState({
        title: '',
        given_name: '',
        family_name: ''
    });

    useEffect(() => {
        const userData = localStorage.getItem('user_data');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser({
                title: parsedUser.title || '',
                given_name: parsedUser.given_name || '',
                family_name: parsedUser.family_name || ''
            });
        }
    }, []);



    return (
        <DefaultStyledBox sx={{background: "linear-gradient(180deg, #f5f7ff 10%,  #ffffff 100%)"}}>
            <Box justifyContent={'center'} alignItems={'center'} sx={{ flexGrow: 1, margin: '2em'}} >
                <Grid2 container spacing={3}>
                    {/* Welcome Section */}
                    <Grid2 size={{xs:12}}>
                        <Typography variant="h4" gutterBottom>
                            Welcome back, {user.title} {user.family_name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {DASHBOARD_CONFIG.welcomeMessage}
                        </Typography>
                    </Grid2>

                    {/* Tips Section */}
                    <Grid2 size={{xs:12, md:4}}>
                        <Paper sx={{ p: 2, height: '100%', borderRadius: 2}}>
                            <Typography variant="h6" gutterBottom>
                                Tips for Better Queries
                            </Typography>
                            <List>
                                {DASHBOARD_CONFIG.tipsList.map((tip, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <TipIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={tip} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid2>

                    {/* Query Form Section */}
                    <Grid2 size={{xs:12, md:8}}>
                        <QueryForm onSubmit={handleQuerySubmit} />
                    </Grid2>

                    {/*/!* Research Progress Section *!/*/}
                    {/*<Grid2 size={{xs:12}}>*/}
                    {/*    <ResearchProgress progress={researchProgress} />*/}
                    {/*</Grid2>*/}

                    {/* Research Activity Section */}
                    <Grid2 size={{xs:12}}>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Your Research Activity
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                {activities.map((activity) => (
                                    <Paper
                                        key={activity.id}
                                        sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <Box>
                                            <Typography variant="subtitle1">
                                                {activity.query}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Status: {activity.status}
                                            </Typography>
                                        </Box>
                                        {activity.status === 'READY' ? (
                                            <Button variant="contained" color="primary">
                                                View Result →
                                            </Button>
                                        ) : (
                                            <Typography variant="body2" color="primary">
                                                In Progress...
                                            </Typography>
                                        )}
                                    </Paper>
                                ))}
                            </Box>
                        </Paper>
                    </Grid2>
                </Grid2>
            </Box>
        </DefaultStyledBox>

    );
};

export default Dashboard;