export const AUTH_API_ENDPOINTS = {
    LOGIN: '/api/auth/login',
    REGISTER: '/api/auth/register',
    CONFIRMATION: '/api/auth/confirmation',
    RESEND_CONFIRMATION: '/api/auth/resend-code',
    LOGOUT: '/api/auth/logout',
    REFRESH_TOKEN: '/api/auth/refresh-token',
    VERIFY_TOKEN: '/api/auth/verify-token',
    VERIFY_SALT: '/api/auth/verify-salt',
} as const;
