import {
    HeroSection,
    FeatureCard,
    MedicalInsight,
    ApproachCard,
    HowDoesItWorkCard,
    QueryTip,
    ExamplesCard,
    PricingSection,
    PartnersSection,
    TrialFormProps,
    ExamplesSectionStyle,
    BusinessCardSection,
    DisclaimerCard,
    FeedbackCard, FeedbackSectionTitle
} from '../types/home.types';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export const HERO_CONTENT: HeroSection = {
    title: {
        firstLine: "Let us be ",
        highlighted: "your research team",
        lastLine: ""
    },
    subtitle: "Blending research expertise and artificial intelligence to rapidly identify trusted data most relevant for your patients.         Medint provides personalized, evidence-based, clinical information to physicians, supporting them in making informed decisions for a broader spectrum of care.",
    ctaButton: {
        text: "Try Medint Now",
        href: "/register"
    },
    style: {
        backgroundImage: "/home_page_images/WAVE.png",
        backgroundGradient: "linear-gradient(10deg, #f5f7ff 0%, #ffffff 100%)"
    }
};

export const DISCLAIMER_CONTENT: DisclaimerCard = {
    title: {
        regular: "Created by Physicians, ",
        highlighted: "for Physicians"
    },
    description: {
        firstString: "Medint.ai was created specifically for physicians, ensuring a service that’s finely tuned to the unique requirements of the profession. Prioritizing quality and guided by the characteristics and needs of each individual patient, we identify the existing evidence and outline possible treatment solutions.",
        secondString: " "
    }
}

export const FEATURE_CARDS: FeatureCard[] = [
    {
        icon: "home_page_images/features/ICON-1.png",
        alt: "Precise",
        title: "Precise Insights",
        description: "Receive the most current and applicable information tailored to your patient's needs."
    },
    {
        icon: "home_page_images/features/ICON-2.png",
        alt: "Precise",

        title: "Time-Saving Efficiency",
        description: "Automate research and focus on patient care."
    },
    {
        icon: "home_page_images/features/ICON-3.png",
        alt: "Precise",

        title: "Human & AI Synergy",
        description: "Benefit from the perfect blend of AI and human expertise."
    },
    {
        icon: "home_page_images/features/ICON-4.png",
        alt: "Precise",

        title: "Enhanced Patient Outcomes",
        description: "Improve treatment accuracy and patient satisfaction."
    }
];

export const MEDICAL_INSIGHTS: MedicalInsight[] = [
    {
        endCount: 2000,
        suffix: '+',
        title: "Personalized Clinical Insights",
        description: "Delivered"
    },
    {
        endCount: 98,
        suffix: '%',
        title: "Accuracy Rate",
        description: "in Medical Insights"
    },
    {
        endCount: 1500,
        suffix: '+',
        title: "Hours Saved",
        description: "for Medical Professionals"
    }
];
//
// export const APPROACH_CARD: ApproachCard[] = [
//     {
//         title: {
//             regular: "Created by Physicians, ",
//             highlighted: "for Physicians"
//         },
//         description: "At Medint, we know that every patient is unique & every decision matters. Our platform integrates vast amounts of medical data with the expertise of experienced researchers to provide you with precise, actionable insights. Whether it's comparing treatment options, summarizing new drugs, listing relevant clinical trials, or reviewing literature, Medint supports you in making the best choices for your patients quickly and confidently",
//         imagePath: 'home_page_images/CIRCLE.png',
//     },
// ]

export const APPROACH_CARD: ApproachCard[] = [
    {
        title: {
            regular: "Created by Physicians, ",
            highlighted: "for Physicians"
        },
        description: "Presenting data is only part of the picture, there is also a need for an in-depth understanding of the individual perspective, including the humane and ethical aspects of medicine. \n" +
            "\n" +
            "AI can’t compete at this point with human intelligence and compassion. Medint therefore offers a service that seamlessly integrates human medical expertise with AI technology. Our team consists of information specialists, data analysts, experienced researchers, and clinicians. This allows us to provide information in a manner that blends professional insight, care, and technology.\n" +
            "\n" +
            "Medint.ai was created specifically for physicians, ensuring a service that’s finely tuned to the unique requirements of the profession. Prioritizing quality and guided by the characteristics and needs of each individual patient, we identify the existing evidence and outline possible treatment solutions.",
        imagePath: 'home_page_images/CIRCLE.png',
    },
]
export const HOW_DOES_IT_WORK_CARD: HowDoesItWorkCard[] = [
    {
        title: {
            regular: "How Does",
            highlighted: " It Work?"
        },
        description: "Medint's unique process involves a comprehensive approach to provide the most relevant and precise medical insights",
        videoPath: ""
    }
]

export const QUERY_TIP: QueryTip[] = [
    {
        title: {
            regular: "What to include in the ",
            highlighted: "Medical Query?"
        },
        carousel_content: {
            first: "Is there a recommendation to screen for JC virus antibodies before administering rituximab?",
            second: "Can lymphoplasmacytic lymphoma be associated with IgG elevation rather than IgM?",
            third: "Are there Pediatric vascular/transplant surgeons or interventional radiologists in Europe (France, Germany ideally) who have experience operating on patients with NF-type 1? "
        }
    }
]

export const EXAMPLES_STYLE: ExamplesSectionStyle = {
    style: {
        backgroundImage: "/home_page_images/WAVE.png",
    }
};

export const EXAMPLES_CARD: ExamplesCard[] = [
    {
        title: {
            regular: "See how Medint can help with",
            highlighted: "real-world dilemmas"
        },
        buttons: [
            {
                text: "Urothelial Carcinoma Treatment Sample",
                icon: ArrowOutwardIcon,
                href: "/mock/report1.pdf"
            },
            {
                text: "JC virus screening and Rituximab",
                icon: ArrowOutwardIcon,
                href: "/mock/report2.pdf"
            },

        ]
    }
]

export const PRICING_DATA: PricingSection[] = [
    {
        title: {
            regular: "See",
            highlighted: "Pricing"
        },
        options: [
            {
                id: "per-query",
                title: "Per medical query",
                discount: {
                    value: 30,
                    label: "Discount"
                },
                price: {
                    amount: 700,
                    currency: "$",
                    position: "left"
                },
            },
            {
                id: "contact-sales",
                title: "Contact a sales representative for quote",
                discount: {
                    value: 40
                },
                isContactOption: true
            }
        ],
        ctaButton: {
            text: "Get in touch",
            href: "/contact",
            icon: ArrowOutwardIcon
        }
    },
]

export const PARTNERS_CONTENT: PartnersSection[] = [
    {
        title: {
            regular: "Partners and ",
            highlighted: "Clients"
        },
        regular: {
            description: "We work in collaboration with leading companies, institutions, and organizations within the medical ecosystem."
        },
        carousel_content: {
            icons: [
                {
                    path: "/home_page_images/partners/minHealth.png",
                    alt: "Ministry of Health Logo",
                    width: 120,
                    height: 60
                },
                {
                    path: "/home_page_images/partners/moschal.png",
                    alt: "Moschal Medical Center Logo",
                    width: 120,
                    height: 60
                },
                {
                    path: "/home_page_images/partners/telAvivUni.png",
                    alt: "Tel Aviv University Logo",
                    width: 120,
                    height: 60
                },
                {
                    path: "/home_page_images/partners/rcm.png",
                    alt: "RCM Logo",
                    width: 120,
                    height: 60
                },
                {
                    path: "/home_page_images/partners/sheba.png",
                    alt: "SHEBA Logo",
                    width: 120,
                    height: 60
                },
                {
                    path: "/home_page_images/partners/technion.png",
                    alt: "Technion University Logo",
                    width: 120,
                    height: 60
                },
            ]
        }
    }
]

export const TRIAL_FORM: TrialFormProps[] = [
    {
        title: {
            regular: [
                "Experience",
                "Medint's Precision"
            ],
            highlighted: "Today",
            description: "Medint answers complex clinical dilemmas for physicians based on academic literature and an AI that is being developed"},
        button: {
            text: "Start Your Free Trial",
            icon: ArrowOutwardIcon
        }
    }
];

export const BUSINESS_CARD: BusinessCardSection = {
    title: {
        regular: ["Let us be your"],
        highlighted: "research team"
    },
    buttons: [
        {
            text: "Experience Medint's Precision Today",
            icon: ArrowOutwardIcon,
            href: "/register"
        },
        {
            text: "Explore Our Technology",
            icon: ArrowOutwardIcon,
            href: "/technology"
        }
    ],
    description: {
        text: "Created by Physicians, for Physicians."
    },
    style: {
        backgroundImage: "/home_pages_images/medint-logo.svg",
        backgroundGradient: "linear-gradient(110deg, #195AF1 10%, #FF7376 100%)"
    }
};


export const FEEDBACK_SECTION: FeedbackSectionTitle = {
    title: "Discover how Medint has transformed medical practices & patient care",
}

export const FEEDBACK_CARDS: FeedbackCard[] = [
    {
        quote: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit am et consectetur, adipisci velit.',
        author: {
            name: 'Jonathan Wang',
            position: 'Physician at company'
        },
        imagePath: 'home_page_images/CIRCLE.png',
    },
];
