import React from 'react';
import { useParams } from 'react-router-dom';
import PdfViewer from "../../../../../utils/PdfViewer";
import {useS3} from "../../../../../hooks/useS3";

interface LocationState {
    pdfUrl: string;
}

const ReportViewer: React.FC = () => {
    const { reportId } = useParams<{ reportId: string }>();
    const { files } = useS3();


    // Find the file by matching the filename part
    const selectedFile = files.find(file => {
        const fileName = file.id.split('/').pop();
        return fileName === reportId;
    });

    if (!selectedFile) {
        return <div>Report not found</div>;
    }

    return <PdfViewer url={selectedFile.pdfUrl} />;
};

export default ReportViewer;