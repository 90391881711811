import React, {useState} from 'react';
import {Card, CardContent, CardActionArea, Typography, Chip, Box, IconButton, Menu, MenuItem} from '@mui/material';
import { MoreVert as MoreIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import {REPORTS_PAGE_CONFIG} from "../../../../../constants/reports.constants";
import {Report} from "../../../../../types/reports.type";

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)'
    }
}));

const StatusChip = styled(Chip)<{ status: string }>(({ theme, status }) => ({
    backgroundColor: REPORTS_PAGE_CONFIG.cardConfig.statusColors[status],
    color: theme.palette.getContrastText(REPORTS_PAGE_CONFIG.cardConfig.statusColors[status]),
    marginBottom: theme.spacing(1)
}));

interface ReportCardProps {
    report: Report;
    onClick: (reportId: string) => void;
    onDownload: (reportId: string) => void;
}

const ReportCard: React.FC<ReportCardProps> = ({ report, onClick, onDownload }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleDownload = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
        onDownload(report.id);
    };

    return (
        <StyledCard>
            <CardActionArea onClick={() => onClick(report.id)} sx={{ height: '100%' }}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <StatusChip
                            label={REPORTS_PAGE_CONFIG.cardConfig.statusLabels[report.status]}
                            status={report.status}
                            size="small"
                        />
                        <IconButton
                            size="small"
                            onClick={handleMenuOpen}
                            sx={{ ml: 1 }}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {report.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        {report.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block">
                        Uploaded on {format(new Date(report.createdAt), REPORTS_PAGE_CONFIG.cardConfig.dateFormat)}
                    </Typography>
                    {report.tags && (
                        <Box sx={{ mt: 2 }}>
                            {report.tags.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    size="small"
                                    sx={{ mr: 0.5, mb: 0.5 }}
                                />
                            ))}
                        </Box>
                    )}
                </CardContent>
            </CardActionArea>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleDownload}>Download</MenuItem>
            </Menu>
        </StyledCard>
    );
};

export default ReportCard;