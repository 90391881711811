import { useState, useEffect } from 'react';
import { Report } from '../types/reports.type';
import {s3Api} from "../services/s3/s3.services";
import {S3_API_ENDPOINTS} from "../constants/s3.constants";

export const useS3 = () => {
    const [files, setFiles] = useState<Report[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const API_URL = process.env.REACT_APP_API_URL;


    const fetchUserFiles = async (userId: string) => {
        try {
            setIsLoading(true);
            // First, get the list of files
            const response = await s3Api.listFiles(userId);

            if (response.status === 'success') {
                // Transform into initial reports without presigned URLs
                const initialReports: Report[] = response.data.files.map((fileKey: string) => {
                    const fileName = fileKey.split('/').pop() || '';
                    return {
                        id: fileKey,
                        title: fileName,
                        description: `File: ${fileName}`,
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                        status: 'published' as const,
                        pdfUrl: '', // Empty initially
                        tags: []
                    };
                });

                setFiles(initialReports);

                // Then update URLs one by one
                for (const fileKey of response.data.files) {
                    const fileResponse = await s3Api.getFile(fileKey);
                    setFiles(prevFiles =>
                        prevFiles.map(file =>
                            file.id === fileKey
                                ? { ...file, pdfUrl: fileResponse.data.url }
                                : file
                        )
                    );
                }
            }
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to fetch files'));
        } finally {
            setIsLoading(false);
        }
    };
    return {
        files,
        isLoading,
        error,
        fetchUserFiles,
    };
};