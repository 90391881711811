import React from 'react';
import { Box, Container, Divider } from '@mui/material';
import FooterLinks from './components/FooterLinks';
import FooterSubscribe from './components/FooterSubscribe';
import FooterSocial from './components/FooterSocialMedia';
import {FOOTER_CONFIG} from "../../constants/footer.constants";

const Footer: React.FC = () => {
    return (
        <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
            <Container maxWidth="lg">
                <FooterLinks />
                <Divider sx={{ my: 4 }} />
                <FooterSubscribe />
                <Divider sx={{ my: 4 }} />
                <FooterSocial
                    social={FOOTER_CONFIG.socialMedia}
                    iconColor="primary"
                />
            </Container>
        </Box>
    );
};

export default Footer;